import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import useGetAboutSection from "../../../../../hooks/data-hooks/admin/about-us/about-section/useGetAboutSection"; // Importing custom hook for API call
import useUpdateAboutSection from "../../../../../hooks/data-hooks/admin/about-us/about-section/useUpdateAboutSection";
import MyImage from "../../../../../custom-components/MyImage";
import useSingleImageUpload from "../../../../../hooks/file-uplod-hooks/useSingleImageUpload";
import Loader from "../../../../common/components/loader/Loader";
import Button from "../../../../web/components/button/Button";

const AboutSection = () => {
  const { data1, isLoading1, isError1 } = useGetAboutSection();
  const [files, setFiles] = useState({});
  const { handleUpload, isLoading } = useSingleImageUpload();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
    setValue,
  } = useForm();

  const handleFileChanges = (event) => {
    // Convert FileList to array
    // const fileList = Array.from(event.target.file);
    setFiles({ ...files, [event.target.name]: event.target.files[0] });
  };

  useEffect(() => {
    // Set default values if data1 is available
    if (data1) {
      Object.entries(data1?.data?.content).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [data1]);

  const { updateAboutSection, isLoadingUpdate, isError, error, submitSuccess } =
    useUpdateAboutSection();

  useEffect(() => {
    // Show error toast if isError is true
    const errorMessage = isError || error || isError;
    if (isError) {
      toast.error(errorMessage);
    }
  }, [isError, error, isError]);

  // useEffect(() => {
  //   // Show success toast if submitSuccess is true
  //   if (submitSuccess) {
  //     toast.success("Form Submit Successful");
  //   }
  // }, [submitSuccess]);

  const onSubmit = async (data) => {
    // Prevent form submission if there is an ongoing login process
    if (isSubmitting) return;

    try {
      const urls = await Promise.all([
        files.founder_image_url
          ? handleUpload(files.founder_image_url)
          : getValues("founder_image_url"),
        files.founder_signature_image_url
          ? handleUpload(files.founder_signature_image_url)
          : getValues("founder_signature_image_url"),
        files.background_image
          ? handleUpload(files.background_image)
          : getValues("background_image"),
      ]);

      // Call the custom hook for API call
      updateAboutSection({
        ...data,
        founder_image_url: urls[0],
        founder_signature_image_url: urls[1],
        background_image: urls[2],
      });
      // reset();
    } catch (error) {
      // Error handling is done in useEffect
    }
  };
  if (isLoading1) return <Loader />;
  return (
    <div className="cms-section">
      <div className="cms-card  ">
        <form className="cms-form   mb-4  " onSubmit={handleSubmit(onSubmit)}>
          <div className="fs-18px fw-bold mb-3">About Section</div>

          <div className="cms-content custom-scroll">
            <div className="form-item">
              <label className="">Title</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className=""
                  placeholder="Enter title name"
                  {...register("title", {
                    required: "Field is required",
                  })}
                />
              </div>
            </div>
            <div className="form-item">
              <label className="">Paragraph</label>
              <div className="input-wrapper">
                <textarea
                  rows={5}
                  type="text"
                  className=""
                  placeholder="Enter paragraph content"
                  {...register("paragraph", {
                    required: "Field is required",
                  })}
                />
              </div>
            </div>
            <div className="form-item">
              <label className="">Founder name</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className=""
                  placeholder="Enter Founder name"
                  {...register("founder_name", {
                    required: "Field is required",
                  })}
                />
              </div>
            </div>
            <div className="form-item">
              <label className="">Founder designation</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className=""
                  placeholder="Enter Founder designation"
                  {...register("founder_designation", {
                    required: "Field is required",
                  })}
                />
              </div>
            </div>
            <div className="form-item">
              <label className="">
                Founder Image URL
                <span className="text-danger fs-10 ">(Max 5 MB)</span>{" "}
              </label>
              <div className="input-wrapper">
                <MyImage
                  src={
                    files?.founder_image_url
                      ? URL.createObjectURL(files?.founder_image_url)
                      : data1?.data?.content?.founder_image_url
                  }
                  className="preview-img"
                />

                <input
                  type="file"
                  onChange={handleFileChanges}
                  name="founder_image_url"
                />
              </div>
            </div>
            <div className="form-item">
              <label className="">
                Founder Signature Image URL
                <span className="text-danger fs-10 ">(Max 5 MB)</span>
              </label>
              <div className="input-wrapper">
                <MyImage
                  src={
                    files?.founder_signature_image_url
                      ? URL.createObjectURL(files?.founder_signature_image_url)
                      : data1?.data?.content?.founder_signature_image_url
                  }
                  className="preview-img"
                />

                <input
                  type="file"
                  onChange={handleFileChanges}
                  name="founder_signature_image_url"
                />
              </div>
            </div>
            <div className="form-item">
              <label className="">
                Background Image URL
                <span className="text-danger fs-10 ">(Max 5 MB)</span>
              </label>
              <div className="input-wrapper">
                <MyImage
                  src={
                    files?.background_image
                      ? URL.createObjectURL(files?.background_image)
                      : data1?.data?.content?.background_image
                  }
                  className="preview-img"
                />

                <input
                  type="file"
                  onChange={handleFileChanges}
                  name="background_image"
                />
              </div>
            </div>
            <div className="form-item">
              <label className="">Experience time count</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className=""
                  placeholder="Enter Experience time count"
                  {...register("experience_time_count", {
                    required: "Field is required",
                  })}
                />
              </div>
            </div>
            <div className="form-item">
              <label className="">Experience time content</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className=""
                  placeholder="Enter Experience time content"
                  {...register("experience_time_content", {
                    required: "Field is required",
                  })}
                />
              </div>
            </div>
          </div>

          <Button
            label={isLoadingUpdate || isLoading ? "Submitting" : "Submit"}
            type="submit"
            className={`w-100 ${
              isLoadingUpdate || isLoading ? "bg-disabled" : ""
            }`}
            disabled={isLoadingUpdate || isLoading}
          />
        </form>
      </div>
    </div>
  );
};
export default AboutSection;
