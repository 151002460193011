import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import useGetWhyChooseUs from "../../../../../hooks/data-hooks/admin/about-us/why-choose-us/useGetWhyChooseUs"; // Importing custom hook for API call
import useUpdateWhyChooseUs from "../../../../../hooks/data-hooks/admin/about-us/why-choose-us/useUpdateWhyChooseUs";
import MyImage from "../../../../../custom-components/MyImage";
import useSingleImageUpload from "../../../../../hooks/file-uplod-hooks/useSingleImageUpload";
import Loader from "../../../../common/components/loader/Loader";
import Button from "../../../../web/components/button/Button";

const WhyChooseUs = () => {
  const { data1, isLoading1, isError1 } = useGetWhyChooseUs();
  const [files, setFiles] = useState({});
  const [progressItems, setProgressItems] = useState([]); // State for progress items

  const { handleUpload, isLoading } = useSingleImageUpload();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
    setValue,
  } = useForm();

  const handleFileChanges = (event) => {
    // Convert FileList to array
    // const fileList = Array.from(event.target.file);
    setFiles({ ...files, [event.target.name]: event.target.files[0] });
  };

  useEffect(() => {
    // Set default values if data1 is available
    if (data1) {
      Object.entries(data1?.data?.content).forEach(([key, value]) => {
        setValue(key, value);
      });
      setProgressItems(data1?.data?.content?.progress_items || []); // Populate progress items from data
    }
  }, [data1]);

  const { updateWhyChooseUs, isLoadingUpdate, isError, error, submitSuccess } =
    useUpdateWhyChooseUs();

  useEffect(() => {
    // Show error toast if isError is true
    const errorMessage = isError || error || isError;
    if (isError) {
      toast.error(errorMessage);
    }
  }, [isError, error, isError]);

  // useEffect(() => {
  //   // Show success toast if submitSuccess is true
  //   if (submitSuccess) {
  //     toast.success("Form Submit Successful");
  //   }
  // }, [submitSuccess]);

  const onSubmit = async (data) => {
    // Prevent form submission if there is an ongoing login process
    if (isSubmitting) return;

    try {
      const urls = await Promise.all([
        files.large_image_url
          ? handleUpload(files.large_image_url)
          : getValues("large_image_url"),
        files.small_image_url
          ? handleUpload(files.small_image_url)
          : getValues("small_image_url"),
        files.background_image
          ? handleUpload(files.background_image)
          : getValues("background_image"),
      ]);

      // Call the custom hook for API call
      updateWhyChooseUs({
        ...data,
        large_image_url: urls[0],
        small_image_url: urls[1],
        background_image: urls[2],
        progress_items: progressItems, // Include progress items in the update data
      });
      // reset();
    } catch (error) {
      // Error handling is done in useEffect
    }
  };
  if (isLoading1) return <Loader />;
  return (
    <div className="cms-section">
      <div className="cms-card  ">
        <form className="cms-form   mb-4  " onSubmit={handleSubmit(onSubmit)}>
          <div className="fs-18px fw-bold mb-3">Why Choose Us</div>

          <div className="cms-content custom-scroll">
            <div className="form-item">
              <label className="">Title</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className=""
                  placeholder="Enter title name"
                  {...register("title", {
                    required: "Field is required",
                  })}
                />
              </div>
            </div>
            <div className="form-item">
              <label className="">Paragraph</label>
              <div className="input-wrapper">
                <textarea
                  rows={5}
                  type="text"
                  className=""
                  placeholder="Enter paragraph content"
                  {...register("paragraph", {
                    required: "Field is required",
                  })}
                />
              </div>
            </div>
            <div className="form-item">
              <label className="">
                Large Image URL
                <span className="text-danger fs-10 ">(Max 5 MB)</span>
              </label>
              <div className="input-wrapper">
                <MyImage
                  src={
                    files?.large_image_url
                      ? URL.createObjectURL(files?.large_image_url)
                      : data1?.data?.content?.large_image_url
                  }
                  className="preview-img"
                />

                <input
                  type="file"
                  onChange={handleFileChanges}
                  name="large_image_url"
                />
              </div>
            </div>
            {/* <div className="form-item">
              <label className="">
                Small Image URL
                <span className="text-danger fs-10 ">(Max 5 MB)</span>
              </label>
              <div className="input-wrapper">
                <MyImage
                  src={
                    files?.small_image_url
                      ? URL.createObjectURL(files?.small_image_url)
                      : data1?.data?.content?.small_image_url
                  }
                  className="preview-img"
                />

                <input
                  type="file"
                  onChange={handleFileChanges}
                  name="small_image_url"
                />
              </div>
            </div> */}
            <div className="form-item">
              <label className="">Progress Items</label>
              <div className="input-wrapper">
                {progressItems.map((item, index) => (
                  <div key={index} className="progress-item">
                    <label className="">Label {index + 1} </label>
                    <input
                      type="text"
                      placeholder="Label"
                      value={item.label}
                      required
                      onChange={(e) =>
                        setProgressItems((prevItems) =>
                          prevItems.map((prevItem, i) =>
                            i === index
                              ? { ...prevItem, label: e.target.value }
                              : prevItem
                          )
                        )
                      }
                    />

                    <label className="">Percentage {index + 1} </label>
                    <input
                      type="text"
                      placeholder="Percentage"
                      value={item.percentage}
                      required
                      onChange={(e) =>
                        setProgressItems((prevItems) =>
                          prevItems.map((prevItem, i) =>
                            i === index
                              ? { ...prevItem, percentage: e.target.value }
                              : prevItem
                          )
                        )
                      }
                    />
                    <hr />
                  </div>
                ))}
              </div>
            </div>
          </div>

          <Button
            label={isLoadingUpdate || isLoading ? "Submitting" : "Submit"}
            type="submit"
            className={`w-100 ${
              isLoadingUpdate || isLoading ? "bg-disabled" : ""
            }`}
            disabled={isLoadingUpdate || isLoading}
          />
        </form>
      </div>
    </div>
  );
};
export default WhyChooseUs;
