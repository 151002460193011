import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import Button from "../../../web/components/button/Button";
import { Link, useNavigate } from "react-router-dom";
import adminPrefixedRoutes from "../../../../routes/all-routes/adminRoutes";
import useGetProductCategoryList from "../../../../hooks/data-hooks/admin/product/useGetProductCategoryList";
import Loader from "../../../common/components/loader/Loader";

const ProductCategory = () => {
  // const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const {
    fetchProductCategory,
    productCategory,
    isLoading,
    updateProductCategoryStatus,
    productCategoryError,
    fetchProductCategoryList,
    totalRows,
    setTotalRows,
  } = useGetProductCategoryList(perPage, currentPage);

  useEffect(() => {
    fetchProductCategory(perPage, currentPage);
  }, [perPage, currentPage]);

  const columns = [
    {
      name: "#",
      selector: (row, index) =>
        (productCategory?.meta?.current_page - 1) * perPage + index + 1,
      sortable: true,
    },
    {
      name: "Category Name",
      selector: (row) => (
        <Link
          to={`/admin/products/product-category/${row._id}`}
          className="link-style"
        >
          {row.name}
        </Link>
      ),
      sortable: true,
    },

    {
      name: "Status",
      selector: (row) => (row.status === "active" ? "Active" : "Inactive"),
      sortable: true,
    },

    {
      name: "Action",
      cell: (row) => (
        <div className="form-switch">
          <input
            className="form-check-input cursor-pointer"
            type="checkbox"
            role="switch"
            checked={row.status === "active"}
            onChange={() => {
              // console.log(row._id);
              updateProductCategoryStatus(row._id);
            }}
          />
        </div>
      ),
    },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // fetchProductCategory(perPage, page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    // setCurrentPage(page);
    // fetchProductCategory(newPerPage, page);
  };

  const tableHeaderstyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "var(--primary-color)",
        color: "#fff",
      },
    },
  };

  const headerComponent = React.useMemo(() => {
    return (
      <div>
        Product Category{" "}
        <Button
          label={"Add New Category"}
          className="ms-3"
          onClick={() => navigate(adminPrefixedRoutes.CREATE_PRODUCT_CATEGORY)}
        />
      </div>
    );
  }, []);

  if (isLoading) return <Loader />;

  return (
    <div className="container">
      <div className="shadow border ">
        <DataTable
          title={headerComponent}
          customStyles={tableHeaderstyle}
          columns={columns}
          data={productCategory?.data}
          progressPending={isLoading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          fixedHeader
          highlightOnHover
        />
      </div>
    </div>
  );
};
export default ProductCategory;
