import React, { useEffect } from "react";
import useGetTransactionDetailsById from "../../../../hooks/data-hooks/admin/transaction/useGetTransactionDetailsById";
import { useParams } from "react-router";
import Loader from "../../../common/components/loader/Loader";

const TransactionDetails = () => {
  const params = useParams();
  const { fetchTransactionDetailWithId, isLoading, transactionDetails } =
    useGetTransactionDetailsById(params.id);

  useEffect(() => {
    fetchTransactionDetailWithId(params.id); // Fetch transaction details when transaction-id/param-id changes
  }, [params.id]);

  const shippingAddress = JSON.parse(
    transactionDetails?.orderId?.shippingAddress || "{}"
  );
  const totalDetails = JSON.parse(transactionDetails?.totalDetails || "{}");

  const date = new Date(transactionDetails?.createdAt);
  const formattedDate = date.toLocaleString("en-US", {
    day: "2-digit",
    month: "short",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  if (isLoading) return <Loader />;
  return (
    <div className="container ">
      {transactionDetails && (
        <div className="row g-4 fs-18">
          <div className="col-lg-6">
            <div className="border border-1 rounded-3  shadow ">
              <div className="mb-4 bg-highlight p-3 d-flex    justify-content-between flex-wrap  ">
                <h5 className="me-2 color-title-color fw-bold ">
                  Transaction ID
                </h5>
                <p className=" m-0 text-wrap  ">{transactionDetails?.userId}</p>
              </div>
              <div className="p-3 pt-0 ">
                <div className="mb-4 d-flex  justify-content-between flex-wrap  ">
                  <h5 className="me-2 color-title-color fw-bold ">Lab Name</h5>
                  <span className="">
                    {transactionDetails?.orderId?.labId?.labName}
                  </span>
                </div>
                <div className="mb-4 d-flex  justify-content-between flex-wrap  ">
                  <h5 className="me-2 color-title-color fw-bold ">
                    Clinic Name
                  </h5>
                  <p>{transactionDetails?.orderId?.clinicId?.clinicName}</p>
                </div>

                <div className="mb-4 d-flex  justify-content-between flex-wrap  ">
                  <h5 className="me-2 color-title-color fw-bold ">
                    Payment Method
                  </h5>
                  <span>{transactionDetails?.paymentMethod}</span>
                </div>
                <div className="mb-4 d-flex  justify-content-between   ">
                  <h5 className="me-2 color-title-color fw-bold ">
                    Shipping Address
                  </h5>
                  <div className="text-end  ">
                    <p className="m-0 ">
                      <span className="">Name :</span> {shippingAddress.name}
                    </p>
                    <p className="m-0 ">
                      <span className="">Phone Number :</span>{" "}
                      {shippingAddress.phoneNumber}
                    </p>
                    <p className="m-0 ">
                      <span className="">Address :</span>{" "}
                      {shippingAddress.address}
                    </p>
                  </div>
                </div>

                <div className="mb-2 d-flex  justify-content-between flex-wrap  ">
                  <h5 className="me-2 color-title-color fw-bold ">
                    Order Date
                  </h5>
                  <span>{formattedDate}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="border border-1 rounded-3 shadow   ">
              <div className="p-3">
                <div className="mb-4 d-flex  justify-content-between flex-wrap  ">
                  <h5 className="me-2 color-title-color fw-bold ">
                    Order Status
                  </h5>
                  <span>{transactionDetails?.orderId?.orderStatus}</span>
                </div>
                <div className="mb-4 d-flex  justify-content-between flex-wrap  ">
                  <h5 className="me-2 color-title-color fw-bold ">
                    Payment Status
                  </h5>
                  <span>{transactionDetails?.orderId?.paymentStatus}</span>
                </div>
                <div className="mb-4 d-flex  justify-content-between flex-wrap  ">
                  <h5 className="me-2 color-title-color fw-bold ">
                    Delivery Status
                  </h5>
                  <span>{transactionDetails?.orderId?.deliveryStatus}</span>
                </div>
                <div className="mb-2 d-flex  justify-content-between flex-wrap  ">
                  <h5 className="me-2 color-title-color fw-bold ">Details</h5>
                  <div className="text-end  ">
                    <p className="m-0 ">
                      <span className="">Discount :</span>{" "}
                      {totalDetails.amount_discount}
                    </p>
                    <p className="m-0 ">
                      <span className="">Shipping :</span>{" "}
                      {totalDetails.amount_shipping}
                    </p>
                    <p className="m-0 ">
                      <span className="">Tax :</span> {totalDetails.amount_tax}
                    </p>
                  </div>
                </div>
              </div>
              <div className="p-3 d-flex  justify-content-between flex-wrap bg-highlight ">
                <h5 className="me-2 color-title-color fw-bold ">
                  Total Amount
                </h5>
                <span>{transactionDetails?.amount}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default TransactionDetails;
