import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import useUpdateBrandSection from "../../../../hooks/data-hooks/admin/homepage/brand-section/useUpdateBrandSection"; // Importing custom hook for API call
import useGetBrandSection from "../../../../hooks/data-hooks/admin/homepage/brand-section/useGetBrandSection";
import Button from "../../../web/components/button/Button";
import MyImage from "../../../../custom-components/MyImage";
import useSingleImageUpload from "../../../../hooks/file-uplod-hooks/useSingleImageUpload";
import Loader from "../../../common/components/loader/Loader";

const BrandSection = () => {
  const { data1, isLoading1, isError1 } = useGetBrandSection();
  const [inputPairs, setInputPairs] = useState(0);
  const [files, setFiles] = useState({});
  const { handleUpload, isLoading } = useSingleImageUpload();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    getValues,
  } = useForm();

  const handleFileChanges = (event) => {
    setFiles({ ...files, [event.target.name]: event.target.files[0] });
  };

  useEffect(() => {
    // Set default values if data1 is available
    if (data1) {
      let listFile = {};
      setValue("title", data1.data.content.title);
      data1.data.content.brand_items.forEach((item, index) => {
        setValue(`brand_items[${index}].image`, item.image);
        listFile = { ...listFile, [index]: item.image };
      });
      setFiles(listFile);
      setInputPairs(data1.data.content?.brand_items.length);
    }
  }, [data1]);

  const handleAddPair = () => {
    setInputPairs((prevCount) => prevCount + 1);
  };

  const { updateContent, isLoadingUpdate, isError, error, submitSuccess } =
    useUpdateBrandSection();

  useEffect(() => {
    // Show error toast if isError is true
    if (isError) {
      toast.error(error.message);
    }
  }, [isError, error]);

  // useEffect(() => {
  //   // Show success toast if submitSuccess is true
  //   if (submitSuccess) {
  //     toast.success("Form Submit Successful");
  //   }
  // }, [submitSuccess]);

  const onSubmit = async (data) => {
    // Prevent form submission if there is an ongoing login process
    if (isSubmitting) return;

    const urls = await Promise.all(
      Array.from({ length: inputPairs }).map((item, index) => {
        return typeof files[index] === "object"
          ? handleUpload(files[index])
          : getValues(`brand_items[${index}].image`);
      })
    );

    try {
      // Call the custom hook for API call
      updateContent({
        ...data,
        brand_items: urls.map((item) => {
          return {
            image: item,
          };
        }),
      });
    } catch (error) {
      // Error handling is done in useEffect
    }
  };

  if (isLoading1) return <Loader />;

  return (
    <div className="cms-section ">
      <div className="cms-card  ">
        <form className="cms-form   mb-4  " onSubmit={handleSubmit(onSubmit)}>
          <div className="fs-18px fw-bold mb-3">Brand Section</div>

          <div className="cms-content custom-scroll">
            <div className="form-item">
              <label className="">Title</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className=""
                  placeholder="Enter title name"
                  {...register("title", {
                    required: "Field is required",
                  })}
                />
              </div>
            </div>
            {/* Mapping over brand_items */}
            {Array.from({ length: inputPairs }).map((_, index) => (
              <div key={index}>
                <div className="form-item">
                  <div className="d-flex justify-content-between">
                    <label>
                      Image - {index + 1}{" "}
                      <span className="text-danger fs-10 ">(Max 5 MB)</span>
                    </label>
                    <button
                      type="button"
                      className="border-0 px-2"
                      onClick={() => {
                        setInputPairs((prevCount) => prevCount - 1);
                        const updatedFiles = { ...files };
                        let newFiles = {};
                        delete updatedFiles[index];
                        Object.entries(updatedFiles).forEach(
                          ([key, val], index) => {
                            newFiles = { ...newFiles, [index]: val };
                          }
                        );
                        // Set the state with the updated files object
                        setFiles(newFiles);
                      }}
                    >
                      X
                    </button>
                  </div>
                </div>
                <div className="form-item">
                  <MyImage
                    src={
                      typeof files[index] === "object"
                        ? URL.createObjectURL(files[index])
                        : data1?.data?.content?.brand_items[index]?.image
                    }
                    className="preview-img"
                  />

                  <input
                    type="file"
                    onChange={handleFileChanges}
                    name={index}
                  />
                </div>
                <hr />
              </div>
            ))}

            <button
              type="button"
              className="w-25 button-primary p-2 mb-3 "
              onClick={handleAddPair}
            >
              Add More
            </button>
          </div>
          <Button
            label={isLoadingUpdate || isLoading ? "Submitting" : "Submit"}
            type="submit"
            className={`w-100 ${
              isLoadingUpdate || isLoading ? "bg-disabled" : ""
            }`}
            disabled={isLoadingUpdate || isLoading}
          />
        </form>
      </div>
    </div>
  );
};
export default BrandSection;
