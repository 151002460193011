const adminPrefix = "admin";
const adminRoutes = {
  DASHBOARD: "/",
  USERS_CLINIC: "/users/clinic",
  USERS_CLINIC_DETAILS: "/users/clinic/details",
  USERS_LAB: "/users/lab",
  USERS_LAB_DETAILS: "/users/lab/details",
  PRODUCT_CATEGORY: "/products/product-category",
  CREATE_PRODUCT_CATEGORY: "/products/add-product-category",
  PRODUCT_LIST: "/products/product-list",
  ORDERS: "/orders",
  TRANSACTION: "/transaction",
  MESSAGE: "/message",
  SETTINGS: "/settings",
  WEB_CONTENT_SETTINGS: "/settings/web-content",
  GENERAL_SETTINGS: "/settings/general-settings",
};

const adminPageNames = {
  DASHBOARD: "Dashboard",
  USERS_CLINIC: "Clinic Management",
  USERS_CLINIC_DETAILS: "Clinic Details",
  USERS_LAB: "Lab Management",
  USERS_LAB_DETAILS: "Lab Details",
  CREATE_PRODUCT_CATEGORY: "Add New Product Category",
  PRODUCT_CATEGORY: "Product Category",
  PRODUCT_LIST: "Product List",
  ORDERS: "Orders",
  TRANSACTION: "Transaction",
  MESSAGE: "Message",
  SETTINGS: "Settings",
  WEB_CONTENT_SETTINGS: "Web Content Settings",
  GENERAL_SETTINGS: "General Settings",
};

const adminPrefixedRoutes = {};

// Loop through each key in adminRoutes and add the prefix
for (const key in adminRoutes) {
  if (adminRoutes.hasOwnProperty(key)) {
    adminPrefixedRoutes[key] = `/${adminPrefix}${adminRoutes[key]}`;
  }
}

export default adminPrefixedRoutes;

export { adminPrefix, adminRoutes, adminPrefixedRoutes, adminPageNames };
