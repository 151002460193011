import React from 'react'
import {ShimmerCircularImage, ShimmerPostDetails,ShimmerSectionHeader  } from "react-shimmer-effects";

function ProfileBarShimmer() {
  return (
    <>
    <div className="text-center "><ShimmerCircularImage size={120} />;</div>
    <ShimmerSectionHeader center  />
    
    <ShimmerPostDetails card cta variant="SIMPLE" />
  </>
  )
}

export default ProfileBarShimmer;