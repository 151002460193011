// LabRouter.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Import Lab panel components
import LabLayout from '../modules/lab/components/layout/LabLayout';
import LabDashboard from '../modules/lab/components/pages/Dashboard';
import LabReports from '../modules/lab/components/pages/Reports';

const LabRouter = () => {
  return (
    <LabLayout>
      <Routes>
        <Route path="/" element={<LabDashboard />} />
        <Route path="/reports" element={<LabReports />} />
      </Routes>
    </LabLayout>
  );
};

export default LabRouter;
