
// slices/signUpSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Defaults to localStorage for web
import encryptionConfig from "../encryptionConfig";

const persistConfig = {
  key: "signUp",
  storage,
  blacklist: [], // Add any state properties that you don't want to persist here
  ...encryptionConfig, // Integrate encryption configuration, if needed
};

export const signUpSlice = createSlice({
  name: "signUp",
  initialState: {
    email: null,
    panel: null,
    hasSignedUp: false, // Add hasSignedUp property with default value false
  },
  reducers: {
    setSignUpData: (state, action) => {
      const { email, panel } = action.payload;
      state.email = email;
      state.panel = panel;
      state.hasSignedUp = true; // Set hasSignedUp to true when sign-up data is set
    },
    clearSignUpData: (state) => {
      state.email = null;
      state.panel = null;
      state.hasSignedUp = false; // Clear hasSignedUp when sign-up data is cleared
    },
  },
});

const persistedSignUpReducer = persistReducer(persistConfig, signUpSlice.reducer);

export const { setSignUpData, clearSignUpData } = signUpSlice.actions;

export default persistedSignUpReducer;
