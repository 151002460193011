// useWebContent.js
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import {
  setData,
  setLoading,
  setError,
} from "../../../redux/slices/webContentSlice";
import { useNavigate } from "react-router-dom";
import { commonRoutes } from "../../../routes/all-routes/commonRoutes";
import authPrefixedRoutes from "../../../routes/all-routes/authRoutes";

const useWebContent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // React Router v6's navigate function
  const webContent = useSelector((state) => state.webContent);

  const { isLoading, isError, data } = useQuery({
    queryKey: ["getwebContent"],
    queryFn: async () => {
      dispatch(setLoading(true));
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/website/website-content`
      );
      if (!response.ok) {
        const text = await response.json();
        if (text?.error?.status === 401) {
          navigate(authPrefixedRoutes.LOGIN);
        }
        dispatch(setError("Failed to fetch data"));
        navigate(commonRoutes.SERVER_ERROR); // Navigate to error500 page
        return;
      }
      const data = await response.json();
      dispatch(setData(data.data));
      dispatch(setLoading(false));
      return data; // Return the fetched data
    },
  });

  return { isLoading, isError, data, webContent };
};

export default useWebContent;
