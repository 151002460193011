import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { logout } from '../../redux/slices/authSlice'; // Update path as per your project structure

const UnAuthGuard = ({ children }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const location = useLocation();

  useEffect(() => {
    // If user is authenticated, dispatch logout action to clear auth data
    if (isAuthenticated) {
      dispatch(logout());
    }
  }, []); 

  useEffect(() => {
    // If user is authenticated, dispatch logout action to clear auth data
    if (isAuthenticated) {
      dispatch(logout());
    }
  }, [location.pathname]); 

  return <>{children}</>;
};

export default UnAuthGuard;
