import React from "react";
import Button from "../button/Button";

const MemberStats = ({ data, serviceCard }) => {
  return (
    <div className="position-relative member-stat-wrapper mt-5">
      {/* <div className="svg-background">
        
      </div> */}
      <div className="position-relative member-stat-content d-flex align-items-center justify-content-center flex-md-row flex-column ">
        {/* Children divs */}
        {data?.content?.map((item) => (
          <div
            key={JSON.stringify(item)}
            className="child-div d-flex justify-content-center align-items-center flex-column mx-3 color-white my-5"
          >
            <p className="font-family-secondary fw-bold ">{item?.count}</p>

            <span className="fw-semibold fs-18">{item?.name}</span>
          </div>
        ))}
      </div>

      <div className="">
        <div className=" member-stat-card p-3 d-flex justify-content-center align-items-center flex-md-row flex-column">
          {serviceCard?.content?.service_card_items?.map((item) => (
            <div className="card m-3 w-md-100" key={JSON.stringify(item)}>
              <div className="card-body d-flex justify-content-center align-items-center flex-column">
                <img src={item?.icon} className="card-img-top" alt="..." />
                <h5 className="card-title mt-2">{item?.title}</h5>
                <p className="card-text text-center">{item?.paragraph}</p>
                <a
                  href ={item?.button_url}
                  className={"col-12 col-sm-auto text-decoration-none text-black btn bg-primary-color color-white-cream"}>{item?.button_text}
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MemberStats;
