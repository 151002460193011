import React from 'react';
import { Rating } from "react-simple-star-rating";
import MyImage from "../../../../custom-components/MyImage";

const TopSellingProductItem = ({ data, allSellCountZero }) => {
  const { name, sellCount, images } = data;

  if (allSellCountZero) {
    return (
      <div className="text-center">
        No Products has been sold yet
      </div>
    );
  }


  if (sellCount === 0) {
    return null;
  }

  return (
    <div className="d-flex gap-2 align-items-center">
      <MyImage addBaseUrl={true} className="w-h-44px rounded-3" src={images[0]} alt="product img" />

      <div className="vstack">
        <span className="text-nowrap text-title">{name}</span>
        <div>
          <Rating initialValue={4} readonly size={15} />
        </div>
      </div>

      <div className="bg-highlight p-2 rounded-2 w-94px">
        <span className="text-nowrap text-primary-color fs-14 fw-light">
          {sellCount} sales
        </span>
      </div>
    </div>
  );
};

export default TopSellingProductItem;