// useGetProductCategoryList.js
import { useEffect, useState } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import authPrefixedRoutes from "../../../../routes/all-routes/authRoutes";
import { useNavigate } from "react-router-dom";

const useGetProductCategoryList = (pageSize, pageNumber) => {
  const token = useSelector((state) => state.auth.user.accessToken);
  const [submitSuccess, setSubmitSuccess] = useState(false); // New state for submit success
  const [error, setError] = useState(null);
  const [productCategory, setProductCategory] = useState({});
  const [productCategoryError, setProductCategoryError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);

  const navigate = useNavigate();

  const fetchProductCategoryList = useQuery({
    queryKey: ["productcategorylist", pageSize, pageNumber],
    queryFn: async () => {
      try {
        // setIsLoading(true);
        const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/admin/category/list?pageSize=${pageSize}&pageNumber=${pageNumber}`;
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          // const text = await response.json();
          // if (text?.error?.status === 401) {
          //   navigate(authPrefixedRoutes.LOGIN);
          // }
          const text1 = await response.text();
          setProductCategoryError(text1);
          // setIsLoading(false);
          throw new Error("Failed to fetch clinic details");
        }

        const responseData = await response.json();
        setProductCategory(responseData); // Assuming responseData contains clinic details
        setTotalRows(responseData.meta.total_count);
        // setIsLoading(false);

        return responseData;
      } catch (error) {
        setProductCategoryError(error);
        // setIsLoading(false);
        throw error;
      }
    },
    onError: (error) => {
      setProductCategoryError(error);
      setIsLoading(false);
      throw error;
    },
  });

  useEffect(() => {
    setTotalRows(productCategory?.meta?.total_count);
  }, [productCategory]);

  useEffect(() => {
    fetchProductCategoryList.refetch();
  }, []);

  const { mutate: updateProductCategoryStatus, isError } = useMutation({
    mutationKey: ["updateproductcategorystatus"],
    mutationFn: async (id) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/admin/category/active-deactivate/${id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();

        if (!response.ok) {
          const text = await response.json();
          if (text?.error?.status === 401) {
            navigate(authPrefixedRoutes.LOGIN);
          }
          throw new Error(data.error.message);
        }

        if (response.status === 200) {
          toast.success("Product Status changed successfully!");
        }

        setProductCategory((prevState) => {
          return {
            ...prevState,
            data: prevState?.data?.map((item) => {
              if (item._id === id)
                return {
                  ...item,
                  status: item.status === "active" ? "deactivate" : "active",
                };
              return item;
            }),
          };
        });

        setSubmitSuccess(true);

        return data;
      } catch (error) {
        // Set login error state
        setError(error);
        throw error; // Re-throw the error to be caught by onError
      } finally {
        setIsLoading(false);
      }
    },
    onError: (error) => {
      setError(error);
      throw error; // Re-throw the error to be caught by onError
    },
  });

  const fetchProductCategory = (pageSize, pageNumber) => {
    fetchProductCategoryList.refetch(pageSize, pageNumber);
  };

  return {
    updateProductCategoryStatus,
    fetchProductCategory,
    productCategory,
    isLoading,
    productCategoryError,
    fetchProductCategoryList,
    totalRows,
    setTotalRows,
  };
};

export default useGetProductCategoryList;
