import React, { useState } from "react";
import { Helmet } from "react-helmet-async";

import HeroSectionContent from "../cms/HeroSectionContent";
import ClinicContent from "../cms/ClinicContent";
import LabContent from "../cms/LabContent";
import ServiceSectionContent from "../cms/ServiceSectionContent";
import ServiceCountSection from "../cms/ServiceCountSection";
import ServiceCardSection from "../cms/ServiceCardSection";
import ContentSection from "../cms/ContentSection";
import TestimonialSection from "../cms/TestimonialSection";
import FaqSection from "../cms/FaqSection";
import BrandSection from "../cms/BrandSection";
import FooterSection from "../cms/FooterSection";
import AboutSection from "../cms/about-us/AboutSection";
import WhyChooseUs from "../cms/about-us/WhyChooseUs";

const WebContentSettings = () => {
  const [tabNum, setTabNum] = useState(1);
  const tabList = [
    "Hero Section",
    "Clinic Content",
    "Lab Content",
    "Service Section Content",
    "Service Count Section",
    "Service Card Section",
    "Content Section",
    "Testimonial Section",
    "FAQ Section",
    "Brand Section",
    "Footer Section",
    "About Section",
    "Why Choose Us",
  ];

  const SideMenu = ({ title, index }) => {
    return (
      <button
        className={`text-start nav-link ${tabNum === index && "active"}`}
        id={`v-pills-${title.toLowerCase()}-tab`}
        data-bs-toggle="pill"
        data-bs-target={`#v-pills-${title.toLowerCase()}`}
        type="button"
        role="tab"
        aria-controls={`v-pills-${title.toLowerCase()}`}
        aria-selected="true"
        onClick={() => setTabNum(index)}
      >
        {title}
      </button>
    );
  };

  const contentList = [
    <HeroSectionContent />,
    <ClinicContent />,
    <LabContent />,
    <ServiceSectionContent />,
    <ServiceCountSection />,
    <ServiceCardSection />,
    <ContentSection />,
    <TestimonialSection />,
    <FaqSection />,
    <BrandSection />,
    <FooterSection />,
    <AboutSection />,
    <WhyChooseUs />
  ];

  const MenuContent = ({ index }) => {
    return contentList[index - 1];
  };

  return (
    <>
      <Helmet>
        <title>Web Content CMS | Admin | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <div className="container">
        <div className="title-32 fw-bold mb-5">Content Management System</div>
        <div className="row">
          <div className="col-lg-4">
            <div className="bg-light shadow p-3 mb-5 bg-body rounded ">
              {/* Content of the first div */}
              <div className="d-flex align-items-start">
                <div
                  className="nav flex-column nav-pills me-3 w-100"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  {tabList?.map((item, index) => (
                    <SideMenu title={item} key={item} index={index + 1} />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="bg-light shadow p-3 mb-5 bg-body rounded h-100">
              {/* Content of the second div */}
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="v-pills-home"
                  role="tabpanel"
                  aria-labelledby="v-pills-home-tab"
                >
                  <MenuContent index={tabNum} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default WebContentSettings;


/* copy */
// import React from "react";
// import { Helmet } from "react-helmet-async";
// import HeroSectionContent from "../cms/HeroSectionContent";

// import ClinicContent from "../cms/ClinicContent";
// import LabContent from "../cms/LabContent";
// import ServiceSectionContent from "../cms/ServiceSectionContent";
// import ServiceCountSection from "../cms/ServiceCountSection";
// import ServiceCardSection from "../cms/ServiceCardSection";
// import ContentSection from "../cms/ContentSection";
// import TestimonialSection from "../cms/TestimonialSection";
// import FaqSection from "../cms/FaqSection";
// import BrandSection from "../cms/BrandSection";
// import FooterSection from "../cms/FooterSection";
// import AboutSection from "../cms/about-us/AboutSection";
// import WhyChooseUs from "../cms/about-us/WhyChooseUs";

// import TestSectionContent from "../cms/TestSectionContent";

// const WebContentSettings = () => {
//   return (
//     <>
//       <Helmet>
//         <title>Settings | Admin | {process.env.REACT_APP_NAME}</title>
//       </Helmet>
//       <div className="container">
//         <div className="title-32 fw-bold mb-5">Content management system</div>

//         <div className="fs-24px fw-bold mb-3">Home Page CMS</div>


//         {/* Routing */}
//         <div className="row gy-4">
//           <HeroSectionContent />
//           <TestSectionContent />
          
          
//           {/*
//           <ClinicContent />
//            <LabContent />
//           <ServiceSectionContent />
//           <ServiceCountSection />
//           <ServiceCardSection />
//           <ContentSection />
//           <TestimonialSection />
//           <FaqSection />
//           <BrandSection />
//           <FooterSection />  */}

//         </div>
//         <hr />
//         <div className="fs-24px fw-bold mb-3">About Us CMS</div>
//         <div className="row gy-4">
//           <AboutSection />
//           <WhyChooseUs />
//         </div>
//       </div>
//     </>
//   );
// };
// export default WebContentSettings;



