import React, { useEffect } from "react";
import phoneCall from "./../../../../assets/svg/contact-us-phone-call.svg";
import mail from "./../../../../assets/svg/contact-us-mail.svg";
import map from "./../../../../assets/svg/contact-us-map.svg";
import discord from "./../../../../assets/svg/contactUsDiscord.svg";
import instagram from "./../../../../assets/svg/contactUsInstagram.svg";
import twitter from "./../../../../assets/svg/contactUsTwitter.svg";
import Button from "../button/Button";
import { useForm } from "react-hook-form";
import useContact from "../../../../hooks/data-hooks/website/useContact";
import { toast } from "react-toastify";

const ContactUsForm = () => {
  const { updateContent, isLoading, isError, error, submitSuccess } =
    useContact();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm();

  useEffect(() => {
    // Show error toast if isError is true
    if (isError) {
      toast.error(error.message);
      setTimeout(() => {
        reset();
      }, 2000);
    }
  }, [isError, error]);

  useEffect(() => {
    // Show success toast if submitSuccess is true
    if (submitSuccess) {
      toast.success("Form Submit Successful");
      setTimeout(() => {
        reset();
      }, 2000);
    }
  }, [submitSuccess]);

  const onSubmit = async (data) => {
    console.log(data);

    // Prevent form submission if there is an ongoing login process

    if (isSubmitting) return;

    try {
      // Call the custom hook for API call
      updateContent(data);
    } catch (error) {
      // Error handling is done in useEffect
    }
  };

  return (
    <div className="bg-contact-us  ">
      <div className="max-width mx-auto d-flex justify-content-center align-items-center flex-column bg-white p-5 shadow-lg rounded-4">
        <div className="row w-100">
          <div className="col-12 col-md-6 mb-3 mb-md-0 p-0 contact-us-left-bg rad-10 ">
            {/* First child */}
            <div className="p-5 h-100 contact-us-container d-flex flex-column justify-content-between align-items-start ">
              <div>
                <h3 className="fw-semibold color-white">Contact Information</h3>
                <span className="mb-5">
                  If you need any help, contact us. Contact information given
                  bellow.
                </span>
              </div>
              <div className="contact-info-wrapper mt-5 mb-5">
                <div className="d-flex align-items-start justify-content-start mt-5 color-white">
                  <img src={phoneCall} alt="Contact Us Phone Call" />
                  <span className="ms-3">+01453456 789</span>
                </div>
                <div className="d-flex align-items-start justify-content-start mt-5 color-white">
                  <img src={mail} alt="Contact Us Mail" />
                  <span className="ms-3">info@yourmail.com</span>
                </div>
                <div className="d-flex align-items-start justify-content-start mt-5 color-white">
                  <img src={map} alt="Contact Us Map" />
                  <span className="ms-3">
                    Flat # S-1 (Level-8), House # 07, SS(D), Road # 09,
                    Gulshan-2, Dhaka-1212
                  </span>
                </div>
              </div>
              <div className="mt-5 contact-us-social-media-container d-flex align-items-center justify-content-start">
                <div className="contact-us-icon-wrapper cursor-pointer">
                  <a href="https://example.com/twitter">
                    <img src={twitter} alt="Twitter" />
                  </a>
                </div>
                <div className="contact-us-icon-wrapper cursor-pointer">
                  <a href="https://example.com/discord">
                    <img src={discord} alt="Discord" />
                  </a>
                </div>
                <div className="contact-us-icon-wrapper cursor-pointer">
                  <a href="https://example.com/instagram">
                    <img src={instagram} alt="Instagram" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 p-0 contact-us-right-container">
            {/* Second child */}
            <div className="p-5">
              <div className="contact-us-right-title-container">
                <h4 className="fw-bold font-family-secondary color-black">
                  Get in <span className="font-color-primary">Touch</span>
                </h4>
                <span>
                  Enim tempor eget pharetra facilisis sed maecenas adipiscing.
                  Eu leo molestie vel, ornare non id blandit netus.
                </span>
              </div>
              <div className="contact-us-right-form-container mt-5">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb-3">
                    <label htmlFor="inputName" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputName"
                      aria-describedby="inputName"
                      placeholder="Your Name Here"
                      {...register(`name`)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email address
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Your Email ID Here"
                      {...register(`email`)}
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="phone" className="form-label">
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder="Your Phone Number"
                      {...register(`phoneNumber`)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="message" className="form-label">
                      Message
                    </label>
                    <textarea
                      id="message"
                      className="form-control"
                      name="message"
                      rows="4"
                      cols="50"
                      placeholder="Type Your Message"
                      {...register(`message`)}
                    >
                      Enter your message here...
                    </textarea>
                  </div>
                  <Button
                    label={isLoading ? "Sending..." : "Send"}
                    className={"w-100"}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsForm;
