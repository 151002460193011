import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import useCommission from "../../../../hooks/data-hooks/admin/generalsetting/useCommission";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Button from "../../../web/components/button/Button";
import Loader from "../../../common/components/loader/Loader";

const Commission = () => {
  const {
    isLoadingCommission,
    placeholder,
    commission,
    updateContent,
    submitSuccess,
    isError
  } = useCommission();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    setValue,
    getValues
  } = useForm();

  useEffect(() => {
    // Show error toast if isError is true
    if (commission) {
      commission?.forEach((item, index) => {
        setValue(`data[${index}].settingValue`, item.settingValue);
        setValue(`data[${index}].settingKey`, item.settingKey);
        setValue(`data[${index}].settingTitle`, item.settingTitle);
      });
    }
  }, [commission]);

  useEffect(() => {
    // Show error toast if isError is true
    if (isError) {
      toast.error(isError);
    }
  }, [isError]);

  useEffect(() => {
    // Show success toast if submitSuccess is true
    if (submitSuccess) {
      toast.success("Form Submit Successful");
    }
  }, [submitSuccess]);

  const onSubmit = async (data, index) => {
    if (isSubmitting) return;
    // Prevent form submission if there is an ongoing login process

    try {
      // Call the custom hook for API call
      updateContent({
        settingValue: data?.data[index]?.settingValue,
        settingKey: data?.data[index]?.settingKey
      });
    } catch (error) {
      // Error handling is done in useEffect
    }
  };

  if (isLoadingCommission) return <Loader />;

  return (
    <div>
      <Helmet>
        <title>Commission | Admin | {process.env.REACT_APP_NAME}</title>
      </Helmet>

      {commission?.map((_, index) => (
        <div key={index}>
          <form
            className="cms-form mb-4"
            onSubmit={handleSubmit((data) => {
              onSubmit(data, index);
            })}
          >
            <div className="fs-18px fw-bold mb-3">
              Commission - {getValues(`data[${index}].settingTitle`)}
            </div>
            <hr />

            <div className="form-item">
              <label>Value (in %)</label>
              <input
                type="number"
                placeholder={`Enter value here`}
                {...register(`data[${index}].settingValue`)}
                required
              />
            </div>
            <Button
              label={isLoadingCommission ? "Submitting" : "Submit"}
              type="submit"
              className={`w-100  ${isLoadingCommission ? "bg-disabled" : ""}`}
              disabled={isLoadingCommission}
            />
          </form>
        </div>
      ))}
    </div>
  );
};

export default Commission;
