import React, { useEffect } from "react";
import ProductDetailsInfo from "../productDetailsInfo/ProductDetailsInfo";
import useGetProductDetailsById from "../../../../hooks/data-hooks/admin/product/useGetProductDetailsById";
import { useParams } from "react-router";
import Loader from "../../../common/components/loader/Loader";

const ProductDetails = () => {
  const params = useParams();
  const { fetchProductDetailWithId, isLoading, productDetails } =
    useGetProductDetailsById(params.id);

  useEffect(() => {
    fetchProductDetailWithId(params.id); // Fetch clinic details when product-id/param-id changes
  }, [params.id]);

  if (isLoading) return <Loader />;
  return (
    <div>
      <ProductDetailsInfo 
      productInfo={productDetails} 
      isLoading={isLoading} />
    </div>
  );
};
export default ProductDetails;
