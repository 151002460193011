import React from "react";
import { ThreeDotIcon } from "../../../../utils/MyIcons";
import TopSellingProductItem from "./TopSellingProductItem";
import useGetTopSellingProduct from "../../../../hooks/data-hooks/admin/dashboard/useGetTopSellingProduct";

const TopSellingProduct = () => {
  const { data1, isLoading1, isError1 } = useGetTopSellingProduct();

  return (
    <div className="bg-white rounded-4 border p-3 vstack gap-2 fixed-height h-100">
      <div className="d-flex justify-content-between align-items-center">
        <span className="text-title">Top Selling Product</span>
        <span onClick={() => {}}>
          <ThreeDotIcon />
        </span>
      </div>

      {/* Add a scrollable container with scrollbar */}
      <div className="dashboard-card-list custom-scroll scrollbar vstack gap-2 ">
        {data1?.data?.map((data) => {
          return (
            <TopSellingProductItem
              key={data.id}
              data={data}
              onClick={() => {}}
            />
          );
        })}
      </div>
    </div>
  );
};

export default TopSellingProduct;
