import { useState } from "react";
import { topClinicData } from "../../../../dummy-data/admin/pages/dashboard/dummy-data";
import TopClinicItem from "./TopClinicItem";
import { ArrowDownBlackIcon } from "../../../../utils/MyIcons";

const TopClinic = () => {
  const [open, setOpen] = useState(false);
  const handleViewAll = () => {
    setOpen(!open);
  };
  return (
    <div className="bg-white border rounded-4   vstack gap-2 h-100">
      <div className="d-flex justify-content-between align-items-center p-3 pb-0 text-title">
        <span>Top clinic</span>
        <span onClick={() => handleViewAll()} className="fs-12 cursor-pointer">
          View all
          <span
            className={`ms-2 ${open ? "arrow-collapsed" : "arrow-expanded"}`}
          >
            <ArrowDownBlackIcon />
          </span>
        </span>
      </div>
      <hr className="m-0 " />
      <div className="d-flex justify-content-between align-items-center px-3 fs-14 text-title">
        <span className="fs-14">Clinic Name</span>
        <span className="fs-14">Total Sell</span>
      </div>
      <div className="dashboard-card-list custom-scroll vstack gap-2 p-3 pt-0 fs-14 fw-lighter">
        {topClinicData.map((data) => {
          return <TopClinicItem key={data.id} data={data} />;
        })}
      </div>
    </div>
  );
};
export default TopClinic;
