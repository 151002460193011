import React from "react";
import State from "../stats/Stats";
import TopSellingProduct from "../topSellingProduct/TopSellingProduct";
import BestLabSeller from "../bestLabSeller/BestLabSeller";
import TopClinic from "../topClinic/TopClinic";
import UserInfo from "../userInfo/UserInfo";
import DashboardAreaChart from "../dashboardAreaChart/DashboardAreaChart";
import DashboardPieChart from "../dashboardPieChart/DashboardPieChart";

const Dashboard = () => {
  return (
    <div className="container   ">
      <div className="row gy-4">
        <div className="col-lg-6 col-xxl-4 ">
          <State />
        </div>
        <div className="col-lg-6 col-xxl-4">
          <DashboardPieChart />
        </div>
        <div className="col-lg-6 col-xxl-4">
          <TopSellingProduct />
        </div>
        <div className="col-lg-6 col-xxl-4">
          <BestLabSeller />
        </div>

        <div className=" col-xxl-8 ">
          <DashboardAreaChart />
        </div>

        <div className="col-xxl-8 ">
          <UserInfo />
        </div>
        <div className="col-lg-6 col-xxl-4">
          <TopClinic />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;