import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import useUpdateServiceCountSection from "../../../../hooks/data-hooks/admin/homepage/service-count-section/useUpdateServiceCountSection"; // Importing custom hook for API call
import useGetServiceCountSection from "../../../../hooks/data-hooks/admin/homepage/service-count-section/useGetServiceCountSection";
import Button from "../../../web/components/button/Button";
import Loader from "../../../common/components/loader/Loader";

const ServiceCountSection = () => {
  const {
    data1,
    isLoading1,
    isError1,
    handleRemovePair,
    serviceCountData,
    placeHolder,
  } = useGetServiceCountSection();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm();

  const [inputPairs, setInputPairs] = useState(0);

  const handleAddPair = () => {
    setInputPairs((prevCount) => prevCount + 1);
  };

  useEffect(() => {
    // Set default values if data1 is available
    if (data1) {
      data1.data.content.forEach((item, index) => {
        Object.entries(item).forEach(([key, value]) => {
          setValue(`${key}_${index}`, value);
        });
      });
      setInputPairs(data1.data.content.length);
    }
  }, [data1]);

  const { updateContent, isLoading, isError, error, submitSuccess } =
    useUpdateServiceCountSection();

  useEffect(() => {
    // Show error toast if isError is true
    if (isError || error || isError1) {
      toast.error(isError || error || isError1);
    }
  }, [isError, error, isError]);

  // useEffect(() => {
  //   // Show success toast if submitSuccess is true
  //   if (submitSuccess) {
  //     toast.success("Form Submit Successful");
  //   }
  // }, [submitSuccess]);

  const onSubmit = async (data) => {
    if (isSubmitting) return;
    try {
      const serviceItems = [];
      for (
        let i = 0;
        data[`name_${i}`] !== undefined && data[`count_${i}`] !== undefined;
        i++
      ) {
        const name = data[`name_${i}`];
        const count = data[`count_${i}`];

        // Create an object for the current service item and push it to the array
        if (data[`name_${i}`] !== "" && data[`count_${i}`] !== "")
          serviceItems.push({ name, count });
      }

      // Create an object with the formatted service items
      const formattedData = { service_items: serviceItems };
      updateContent(formattedData);
    } catch (error) {
      // Error handling is done in useEffect
    }
  };

  if (isLoading1) return <Loader />;
  return (
    <div className="cms-section ">
      <div className="cms-card  ">
        <form className="cms-form   mb-4  " onSubmit={handleSubmit(onSubmit)}>
          <div className="fs-18px fw-bold mb-3">Service Count Section</div>

          <div className="cms-content custom-scroll">
            {Array.from({ length: inputPairs }).map((_, index) => (
              <div key={index}>
                <div className="form-item">
                  <div className="d-flex justify-content-between">
                    <label>Name - {index + 1}</label>
                    <button
                      type="button"
                      className="border-0 px-2"
                      onClick={() => {
                        handleRemovePair(
                          index,

                          serviceCountData[index]?.name,
                          serviceCountData[index]?.count
                        );
                        setInputPairs((prevCount) => prevCount - 1);
                        setValue(`name_${index}`, "");
                        setValue(`count_${index}`, "");
                      }}
                    >
                      X
                    </button>
                  </div>
                  <input
                    rows={5}
                    placeholder={placeHolder?.service_items?.name}
                    {...register(`name_${index}`, {
                      required: "Field is required",
                    })}
                  />
                </div>
                <div className="form-item">
                  <label>Count - {index + 1}</label>
                  <input
                    rows={5}
                    placeholder={placeHolder?.service_items?.count}
                    {...register(`count_${index}`, {
                      required: "Field is required",
                    })}
                  />
                </div>
                <hr />
              </div>
            ))}
            <button
              type="button"
              className="w-25 button-primary p-2 mb-3 "
              onClick={handleAddPair}
            >
              Add More
            </button>
          </div>

          <Button
            label={isLoading ? "Submitting" : "Submit"}
            type="submit"
            className={`w-100 ${isLoading ? "bg-disabled" : ""}`}
            disabled={isLoading}
          />
        </form>
      </div>
    </div>
  );
};
export default ServiceCountSection;
