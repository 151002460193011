import React from "react";
import { ShimmerThumbnail } from "react-shimmer-effects";

function PaginationShimmer() {
  return (
    <>
      {new Array(3).fill(null).map((_, index) => (
        <div className="mx-2"><ShimmerThumbnail key={index} height={30} /></div>        
      ))}
    </>
  );
}

export default PaginationShimmer;
