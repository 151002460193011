import { ThreeDotIcon, StateIcon } from "../../../../utils/MyIcons";
const StatsCard = ({ data }) => {
  const { title, sales, duration } = data;
  return (
    <div className=" w-50 stats-card d-flex flex-column text-center p-3 gap-2">
      {/* card header */}
      <div className="d-flex justify-content-between align-items-center ">
        <div>
          <StateIcon />
          <span className="fs-6 ps-1 text-title ">{title}</span>
        </div>
        <span onClick={() => {}}>
          <ThreeDotIcon />
        </span>
      </div>

      {/* card body */}
      <div>
        <p className="fs-5 mb-0 fw-semibold text-title ">{sales}</p>
        <p className="fs-14 mb-0">{duration}</p>
      </div>
    </div>
  );
};
export default StatsCard;
