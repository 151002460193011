import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import useCreateProductCategory from "../../../../hooks/data-hooks/admin/product/useCreateProductCategory";

const CreateProductCategory = () => {
  const { updateContent, isLoading, isError, error, submitSuccess } =
    useCreateProductCategory();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue
  } = useForm();

  useEffect(() => {
    // Show error toast if isError is true
    if (isError) {
      toast.error(error.message);
    }
  }, [isError, error]);

  useEffect(() => {
    // Show success toast if submitSuccess is true
    if (submitSuccess) {
      toast.success("Form Submit Successful");
    }
  }, [submitSuccess]);

  const onSubmit = async (data) => {
    // Prevent form submission if there is an ongoing login process
    if (isSubmitting) return;

    try {
      // Call the custom hook for API call
      await updateContent(data);
    } catch (error) {
      // Error handling is done in useEffect
    }
  };

  return (
    <div className="ms-5 col-lg-6">
      <div className="cms-card  ">
        <form className="cms-form   mb-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="fs-24 fw-bold ">Category Form</div>

          <div className="mt-5">
            <div className="form-item">
              <label className="">Category Name</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className=""
                  placeholder="Enter category name"
                  {...register("name", {
                    required: "Category name is required"
                  })}
                />
              </div>
            </div>
          </div>

          <button type="submit" className="w-100 button-primary h-64px">
            Add Category
          </button>
        </form>
      </div>
    </div>
  );
};
export default CreateProductCategory;
