import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import useUpdateServiceSection from "../../../../hooks/data-hooks/admin/homepage/service-section/useUpdateServiceSection"; // Importing custom hook for API call
import useGetServiceSection from "../../../../hooks/data-hooks/admin/homepage/service-section/useGetServiceSection";
import useSingleImageUpload from "../../../../hooks/file-uplod-hooks/useSingleImageUpload";
import MyImage from "../../../../custom-components/MyImage";
import Loader from "../../../common/components/loader/Loader";
import Button from "../../../web/components/button/Button";

const ServiceSectionContent = () => {
  const { data1, isLoading1, isError1, serviceData } = useGetServiceSection();
  const [files, setFiles] = useState({});
  // console.log(serviceData, data1, isLoading1);
  const { handleUpload, isLoading } = useSingleImageUpload();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    getValues,
  } = useForm();

  useEffect(() => {
    if (data1) {
      let listFile = {};

      // Loop through each service item in the data and set default values for form fields
      data1?.data?.content?.forEach((item, index) => {
        listFile = {
          ...listFile,
          [`bg-img-${index}`]: item.background_image,
          [`img-${index}`]: item.image,
        };
        setValue(
          `service_items[${index}].background_image`,
          item?.background_image
        );
        setValue(`service_items[${index}].title`, item?.title);
        setValue(`service_items[${index}].image`, item?.image);
      });
    }
  }, [serviceData, data1]);

  const { updateContent, isLoadingUpdate, isError, error, submitSuccess } =
    useUpdateServiceSection();

  useEffect(() => {
    // Show error toast if isError is true
    if (isError) {
      toast.error(error.message);
    }
  }, [isError, error]);

  // useEffect(() => {
  //   // Show success toast if submitSuccess is true
  //   if (submitSuccess) {
  //     toast.success("Form Submit Successful");
  //   }
  // }, [submitSuccess]);

  const onSubmit = async (data) => {
    if (isSubmitting) return;
    console.log(files);
    const newData = await Promise.all(
      data?.service_items?.map(async (item, index) => {
        // Check if background_image is an object and needs to be uploaded
        const backgroundImage =
          typeof files[`bg-img-${index}`] === "object"
            ? await handleUpload(files[`bg-img-${index}`])
            : item.background_image;

        // Check if image is an object and needs to be uploaded
        const image =
          typeof files[`img-${index}`] === "object"
            ? await handleUpload(files[`img-${index}`])
            : item.image;

        return {
          ...item,
          background_image: backgroundImage,
          image: image,
        };
      })
    );

    try {
      // Call the custom hook for API call
      updateContent({ service_items: newData });
    } catch (error) {
      // Error handling is done in useEffect
    }
  };

  const handleFileChanges = (event) => {
    setFiles({ ...files, [event.target.name]: event.target.files[0] });
  };

  if (isLoading1) return <Loader />;

  return (
    <div className="cms-section ">
      <div className="cms-card  ">
        <form className="cms-form   mb-4  " onSubmit={handleSubmit(onSubmit)}>
          <div className="fs-18px fw-bold mb-3">Service Section Content</div>

          <div className="cms-content custom-scroll">
            {data1?.data?.content?.map((_, index) => (
              <div key={index}>
                <div className="d-flex justify-content-between">
                  <span className="fs-5 fw-bold">Service {index + 1}</span>
                </div>
                <div className="form-item">
                  <div className="d-flex justify-content-between">
                    <label>
                      Background Image {index + 1}
                      <span className="text-danger fs-10 ">(Max 5 MB)</span>
                    </label>
                  </div>
                  <div className="input-wrapper">
                    <div className="form-item">
                      <MyImage
                        src={
                          typeof files[`bg-img-${index}`] === "undefined"
                            ? data1?.data?.content[index]?.background_image
                            : URL?.createObjectURL(files[`bg-img-${index}`])
                        }
                        className="preview-img"
                      />
                    </div>

                    <input
                      type="file"
                      onChange={handleFileChanges}
                      name={`bg-img-${index}`}
                    />
                  </div>
                </div>
                <div className="form-item">
                  <label>Title {index + 1} </label>
                  <div className="input-wrapper">
                    <div className="form-item"></div>

                    <input
                      type="text"
                      placeholder={`Enter title ${index + 1}`}
                      {...register(`service_items[${index}].title`, {
                        required: "Field is required",
                      })}
                    />
                  </div>
                </div>
                <div className="form-item">
                  <label>
                    Image {index + 1}{" "}
                    <span className="text-danger fs-10 ">(Max 5 MB)</span>
                  </label>
                  <div className="input-wrapper">
                    <MyImage
                      src={
                        typeof files[`img-${index}`] === "undefined"
                          ? data1?.data?.content[index]?.image
                          : URL?.createObjectURL(files[`img-${index}`])
                      }
                      className="preview-img"
                    />
                    <input
                      type="file"
                      onChange={handleFileChanges}
                      name={`img-${index}`}
                    />
                  </div>
                </div>

                <hr />
              </div>
            ))}
          </div>

          <Button
            label={isLoadingUpdate || isLoading ? "Submitting" : "Submit"}
            type="submit"
            className={`w-100 ${
              isLoadingUpdate || isLoading ? "bg-disabled" : ""
            }`}
            disabled={isLoadingUpdate || isLoading}
          />
        </form>
      </div>
    </div>
  );
};
export default ServiceSectionContent;
