import React from 'react';
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useQuery } from "@tanstack/react-query";
// import {
//   setData,
//   setLoading,
//   setError
// } from "../../../redux/slices/webContentSlice";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const useGetUserInfoSection = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate(); // React Router v6's navigate function
    const token = useSelector((state) => state.auth.user.accessToken);

    const { isLoading, isError, data } = useQuery({
      queryKey: ["userInfoSection"],
      queryFn: async () => {
        //  dispatch(setLoading(true));
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/admin/user-management/user-list`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          // navigate(commonRoutes.SERVER_ERROR); // Navigate to error500 page
          return;
        }
        const data = await response.json();
        //  dispatch(setData(data.data));
        //  dispatch(setLoading(false));
        return data; // Return the fetched data
      },
    });

    useEffect(() => {
      // Fetch data on component mount
      // Alternatively, you can trigger the query explicitly when needed
    }, []);

    return { isLoading1: isLoading, isError1: isError, data1: data };
};

export default useGetUserInfoSection;