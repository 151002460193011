import React from 'react'

function NoDataInTable() {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
     No Item Available
  </div>
  )
}

export default NoDataInTable