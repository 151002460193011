// useGetServiceSection.js
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { commonRoutes } from "../../../../../routes/all-routes/commonRoutes";
import { useSelector } from "react-redux";
import authPrefixedRoutes from "../../../../../routes/all-routes/authRoutes";

const useGetServiceSection = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.user.accessToken);
  const [serviceData, setServiceData] = useState();

  const { isLoading, isError, data } = useQuery({
    queryKey: ["getServiceSection"],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/admin/website-content/home-page/service-section`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        const text = await response.json();
        if (text?.error?.status === 401) {
          navigate(authPrefixedRoutes.LOGIN);
        }
        navigate(commonRoutes.SERVER_ERROR); // Navigate to error500 page
        return;
      }
      const data = await response.json();
      setServiceData(data?.data?.content);
      return data; // Return the fetched data
    }
  });

  return { isLoading1: isLoading, isError1: isError, data1: data, serviceData };
};

export default useGetServiceSection;
