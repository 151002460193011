import React from 'react'
import { ShimmerThumbnail,ShimmerTable } from "react-shimmer-effects";

function TableShimmer() {
  return (
    <>
    {/* {new Array(10).fill(null).map((_, index) => (
      <div className="my-1 mx-2"><ShimmerThumbnail key={index} height={40} /></div>        
    ))} */}
    <ShimmerTable row={10} col={5} />;
  </>
  )
}

export default TableShimmer;