// Dashboard.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Dashboard = () => {
  return (
    <Container className="mt-4">
      <Row>
        <Col>
          <h1>Clinic Dashboard</h1>
          {/* Your dashboard content goes here */}
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
