import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import useUpdateHeroSection from "../../../../hooks/data-hooks/admin/homepage/hero-section/useUpdateHeroSection"; // Importing custom hook for API call
import useGetHeroSection from "../../../../hooks/data-hooks/admin/homepage/hero-section/useGetHeroSection"; //importing custom hook for get API
import MyImage from "../../../../custom-components/MyImage";
import useSingleImageUpload from "../../../../hooks/file-uplod-hooks/useSingleImageUpload";
import Button from "../../../web/components/button/Button";
import Loader from "../../../common/components/loader/Loader";

const HeroSectionContent = () => {
  const { data1, isLoading1, isError1 } = useGetHeroSection();
  // console.log(data1);
  const { isLoading, handleUpload, error } = useSingleImageUpload();

  const [files, setFiles] = useState(null);

  const handleFileChanges = (event) => {
    setFiles(event.target.files[0]);
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm();

  useEffect(() => {
    // Set default values if data1 is available
    if (data1) {
      Object.entries(data1.data.content).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [data1]);

  const {
    updateContent,
    isLoadingUpdate,
    isError,
    errorUpdate,
    submitSuccess,
  } = useUpdateHeroSection();

  useEffect(() => {
    // Show error toast if isError is true
    if (isError || error || isError1 || errorUpdate) {
      toast.error(isError || error || isError1 || errorUpdate);
    }
  }, [isError, error, isError1, errorUpdate]);

  // useEffect(() => {
  //   // Show success toast if submitSuccess is true
  //   if (submitSuccess) {
  //     toast.success("Form Submit Successful");
  //   }
  // }, [submitSuccess]);

  const onSubmit = async (data) => {
    if (isSubmitting) return;
    try {
      const submittedUrl =
        files !== null ? await handleUpload(files) : data.image_url;
      const formattedData = { ...data, image_url: submittedUrl };
      updateContent(formattedData);
    } catch (error) {
      // Error handling is done in useEffect
    }
  };

  if (isLoading1) return <Loader />;
  return (
    <div className="cms-section ">
      <div className="cms-card  ">
        <form className="cms-form   mb-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="fs-18px fw-bold ">Hero Section Content</div>

          <div className=" cms-content custom-scroll ">
            <div className="form-item">
              <label className="">Title</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className=""
                  placeholder="Enter title name"
                  {...register("title", {
                    required: "Field is required",
                  })}
                />
              </div>
            </div>

            <div className="form-item">
              <label className="">Paragraph</label>
              <div className="input-wrapper">
                <textarea
                  rows={5}
                  type="text"
                  className=""
                  placeholder="Enter paragraph content"
                  {...register("paragraph", {
                    required: "Field is required",
                  })}
                />
              </div>
            </div>

            <div className="form-item">
              <label className="">Button text</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className=""
                  placeholder="Enter button text"
                  {...register("button_text", {
                    required: "Field is required",
                  })}
                />
              </div>
            </div>

            <div className="form-item">
              <label className="">Button URL</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className=""
                  placeholder="Enter button URL content"
                  {...register("button_url", {
                    required: "Field is required",
                  })}
                />
              </div>
            </div>

            <div className="form-item">
              <label className="">Video button text</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className=""
                  placeholder="Enter video button text "
                  {...register("video_button_text", {
                    required: "Field is required",
                  })}
                />
              </div>
            </div>

            <div className="form-item">
              <label className="">Video URL</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className=""
                  placeholder="Enter video URL"
                  {...register("video_url", {
                    required: "Field is required",
                  })}
                />
              </div>
            </div>

            <div className="form-item">
              <label className="">
                Image <span className="text-danger fs-10 ">(Max 5 MB)</span>
              </label>
              <div className="input-wrapper">
                <MyImage
                  src={
                    files
                      ? URL.createObjectURL(files)
                      : data1?.data?.content?.image_url
                  }
                  className="preview-img"
                />

                <input type="file" onChange={handleFileChanges} />
              </div>
            </div>
          </div>

          <Button
            label={isLoading || isLoadingUpdate ? "Submitting" : "Submit"}
            type="submit"
            className={`w-100 ${
              isLoading || isLoadingUpdate ? "bg-disabled" : ""
            }`}
            disabled={isLoading || isLoadingUpdate}
          />
        </form>
      </div>
    </div>
  );
};
export default HeroSectionContent;
