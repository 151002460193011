import { useState } from 'react';
import { useMutation } from "@tanstack/react-query";
import { clearSignUpData } from "../../../redux/slices/signUpSlice";
import { useDispatch } from "react-redux";
import authPrefixedRoutes from '../../../routes/all-routes/authRoutes';
import { useNavigate } from 'react-router-dom';


const useVerifySignUp = () => {
    const dispatch = useDispatch();
    const [verifyError, setVerifyError] = useState(null);
    const [verifySubmitSuccess, setVerifySubmitSuccess] = useState(false);
    const [verifySuccessResponse, setVerifySuccessResponse] = useState(null);

    const navigate = useNavigate();

    const { mutate: verifySignUpLab, isLoading: isVerifyLoading, isError: isVerifyError } = useMutation({
        mutationKey: "verifySignUpLab",
        mutationFn: async (formData) => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/auth/verify-email`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(formData),
                });

                const data = await response.json();

                if (!response.ok) {
                    const text = await response.json();
                    if (text?.error?.status === 401) {
                      navigate(authPrefixedRoutes.LOGIN);
                    }
                    setVerifyError(data.error);
                    throw new Error(data.error.message);
                }

                setVerifySubmitSuccess(true);
                setVerifySuccessResponse(data);

                // clear data so that , user cannot use the verification screen without email
                dispatch(clearSignUpData());

                // Delay navigation for 1 second
                setTimeout(() => {
                    // redirect to clinic+lab panel
                    window.location.href = `${process.env.REACT_APP_LAB_CLINIC_PANEL_URL}`;
                }, 1500);

                return data;
            } catch (error) {
                setVerifyError(error);
                throw error;
            }
        },
        onError: (error) => {
            setVerifyError(error);
            throw error;
        },
    });

    return { verifySignUpLab, isVerifyLoading, isVerifyError, verifyError, verifySubmitSuccess, verifySuccessResponse };
};

export default useVerifySignUp;
