import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import useUpdateContentSection from "../../../../hooks/data-hooks/admin/homepage/content-section/useUpdateContentSection"; // Importing custom hook for API call
import useGetContentSection from "../../../../hooks/data-hooks/admin/homepage/content-section/useGetContentSection";
import Button from "../../../web/components/button/Button";
import Loader from "../../../common/components/loader/Loader";

const ContentSection = () => {
  const { data1, isLoading1, isError1 } = useGetContentSection();
  // console.log(data1?.data?.content);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },

    setValue,
  } = useForm();

  useEffect(() => {
    // Set default values if data1 is available
    if (data1) {
      setValue("title", data1.data.content.title);
      data1?.data?.content?.content_items.forEach((item, index) => {
        setValue(`content_items[${index}].title`, item.title);
        setValue(`content_items[${index}].paragraph`, item.paragraph);
      });
    }
  }, [data1]);

  const { updateContent, isLoading, isError, error, submitSuccess } =
    useUpdateContentSection();
  
  console.log(isLoading);

  useEffect(() => {
    // Show error toast if isError is true
    if (isError) {
      toast.error(error.message);
    }
  }, [isError, error]);

  // useEffect(() => {
  //   // Show success toast if submitSuccess is true
  //   if (submitSuccess) {
  //     toast.success("Form Submit Successful");
  //   }
  // }, [submitSuccess]);

  const onSubmit = async (data) => {
    // Prevent form submission if there is an ongoing login process
    if (isSubmitting) return;

    try {
      // Call the custom hook for API call
      updateContent(data);
    } catch (error) {
      // Error handling is done in useEffect
    }
  };

  if (isLoading1) return <Loader />;
  return (
    <div className="cms-section ">
      <div className="cms-card  ">
        <form className="cms-form   mb-4  " onSubmit={handleSubmit(onSubmit)}>
          <div className="fs-18px fw-bold mb-3">Content Section</div>

          <div className="cms-content custom-scroll">
            <div className="form-item">
              <label className="">Title</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className=""
                  placeholder="Enter title name"
                  {...register("title", {
                    required: "Field is required",
                  })}
                />
              </div>
            </div>
            {data1 &&
              data1.data.content.content_items.map((item, index) => (
                <React.Fragment key={index}>
                  <div className="form-item">
                    <label className="">Content Title {index + 1}</label>
                    <div className="input-wrapper">
                      <input
                        type="text"
                        className=""
                        placeholder={`Enter title name`}
                        {...register(`content_items[${index}].title`, {
                          required: "Field is required",
                        })}
                      />
                    </div>
                  </div>
                  <div className="form-item">
                    <label className="">Content Paragraph {index + 1}</label>
                    <div className="input-wrapper">
                      <textarea
                        rows={5}
                        type="text"
                        className=""
                        placeholder="Enter paragraph content"
                        {...register(`content_items[${index}].paragraph`, {
                          required: "Field is required",
                        })}
                      />
                    </div>
                  </div>
                </React.Fragment>
              ))}
          </div>
          <Button
            label={isLoading ? "Submitting" : "Submit"}
            type="submit"
            className={`w-100 ${isLoading ? "bg-disabled" : ""}`}
            disabled={isLoading}
          />
        </form>
      </div>
    </div>
  );
};
export default ContentSection;
