import React from "react";
import { Logo } from "../../../../utils/WebIcons";

function SiteLogoContainer({ logoColor = "black" }) {
  return (
    <div className="d-flex h-40px align-items-center">
      <span className="icon-48 icon-primary">
        <Logo />
      </span>
      <div className="px-12px">
        <div
          className={`fs-24px font-family-secondary  fw-bolder ${
            logoColor === "white" ? "font-color-white" : "font-color-title"
          }`}
        >
          {process.env.REACT_APP_NAME}
        </div>
        <div
          className={`fs-12px fw-semibold ${
            logoColor === "white" ? "font-color-white" : ""
          }`}
        >
          {process.env.REACT_APP_MOTTO}
        </div>
      </div>
    </div>
  );
}

export default SiteLogoContainer;
