import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import adminPrefixedRoutes from "../../../../routes/all-routes/adminRoutes";
import {
  ClinicIcon,
  LabIcon,
  OrderIcon,
  OrdersIcon,
  OrdersRoundedIcon,
} from "../../../../utils/MyIcons";
import MessageBadge from "./MessageBadge";

function formatDate(isoDate) {
  const date = new Date(isoDate);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");

  let amPm = "AM";
  if (hours >= 12) {
    amPm = "PM";
    hours %= 12;
  }
  if (hours === 0) {
    hours = 12;
  }

  return `${day}/${month}/${year} ${hours}:${minutes} ${amPm}`;
}

const MessageItem = ({ messageItem, handleAddMessage, order }) => {
  const params = useParams();
  const { orderId, clinicId, labId, lastMessageTime, totalMessages } =
    messageItem;
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`${adminPrefixedRoutes.MESSAGE}/${messageItem?._id}`);
    handleAddMessage(messageItem);
  };

  // Dynamically assign the background color based on isActive state
  // console.log(order?._id, params?.id);
  return (
    <div
      onClick={handleClick}
      style={{
        cursor: "pointer",
        backgroundColor:
          messageItem?._id === params?.id ? "var(--highlight-color)" : "",
      }}
    >
      <div className="d-flex justify-content-center align-items-center border p-3">
        {/* <img className='w-25 h-50' src={image} alt="" /> */}
        <div className="ms-1 w-100">
          <div className="d-flex justify-content-between flex-column">
            <div className="fs-14 fw-bold  d-flex justify-content-between ">
              <div>
                <span className="message-svg">
                  <OrderIcon />
                </span>
                <span className="ms-1 text-break"> {orderId}</span>
              </div>
              <span>
                <MessageBadge messageCount={totalMessages} />
              </span>
            </div>

            <div className="d-block d-md-flex justify-content-between">
              <div className="fs-12 d-flex justify-content-start  me-1 ">
                <span className="message-svg">
                  <ClinicIcon />
                </span>
                <span className="ms-1">{clinicId?.clinicName}</span>
              </div>

              <div className="fs-12 d-flex justify-content-end ">
                <span className="me-1">{labId?.labName}</span>
                <span className="message-svg">
                  <LabIcon />
                </span>
              </div>
            </div>

            {lastMessageTime && (
              <h5 className="fs-10 d-flex mt-2 justify-content-end">
                <span className="ms-1 text-break">
                  {lastMessageTime
                    ? formatDate(lastMessageTime)
                    : "No Chat yet"}
                </span>
              </h5>
            )}
            {/* <span>{lastTime}am</span> */}
          </div>
          {/* <p className="mb-0">{orderDescription}</p> */}
        </div>
      </div>
    </div>
  );
};

export default MessageItem;
