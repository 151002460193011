import React, { useEffect, useState, useCallback, useMemo } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
// import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import authPrefixedRoutes from "../../../../routes/all-routes/authRoutes";

// const removeItem = (array, item) => {
//   const newArray = array.slice();
//   newArray.splice(
//     newArray.findIndex((a) => a === item),
//     1
//   );

//   return newArray;
// };

const ProductList = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.user.accessToken);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const getProduct = async (page, size = perPage) => {
    try {
      const req = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/admin/product-management/products?pageSize=${size}&pageNumber=${page}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const res = await req.json();
      // console.log(res);
      if (res?.error?.status === 401) {
        navigate(authPrefixedRoutes.LOGIN);
      }
      setData(res);
      setTotalRows(res.meta.pagination.total_count);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setTotalRows(data?.meta?.pagination?.total_count);
  }, [data]);

  useEffect(() => {
    setLoading(true);
    getProduct(1);
    setLoading(false);
  }, []);

  const updateProductCategoryStatus = async (id) => {
    try {
      const req = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/admin/product-management/product/enable-disable/${id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // const res = await req.json();
      // console.log(req);
      if (req.status === 200) {
        toast.success("Product Status changed successfully!");
      }
    } catch (error) {
      console.log(error);
      toast.error("Error in changing Status");
    }
    getProduct(currentPage, perPage);
  };

  const columns = [
    {
      name: "#",
      selector: (row, index) =>
        (data?.meta?.pagination?.current_page - 1) * perPage + index + 1,
      sortable: true,
    },
    {
      name: "Product Name",
      selector: (row) => (
        <Link
          to={`/admin/products/product-list/${row._id}`}
          className="link-style"
        >
          {row.name}
        </Link>
      ),
      sortable: true,
    },
    {
      name: "Lab Name",
      selector: (row) => (
        // <Link
        //   to={`/admin/products/product-list/${row._id}`}
        //   className="link-style"
        // >
          
        // </Link>
        <>
          {row.labId.labName}
        </>
      ),
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => {
        // <Link
        //   to={`/admin/products/product-list/${row._id}`}
        //   className="link-style"
        // >
        //   {row.price}
        // </Link>
        
        return row.price;

    },
      sortable: true,
    },
    {
      name: "Stock",
      selector: (row) => {

        // <Link
        //   to={`/admin/products/product-list/${row._id}`}
        //   className="link-style"
        // > 
        //   {row.stock}
        // </Link>

        return row.stock;
      },
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (
        // <Link
        //   to={`/admin/products/product-list/${row._id}`}
        //   className="link-style"
        // >
        //   {row?.isEnable === true ? "Active" : "Inactive"}
        // </Link>
          
        <>
          {row?.isEnable === true ? "Active" : "Inactive"}
        </>
      ),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="form-switch ">
          <input
            className="form-check-input cursor-pointer"
            type="checkbox"
            role="switch"
            checked={row.isEnable === true}
            onChange={() => {
              updateProductCategoryStatus(row._id);
              getProduct(currentPage, perPage);
            }}
          />
        </div>
      ),
    },
  ];

  const handlePageChange = (page) => {
    getProduct(page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    getProduct(page, newPerPage);
    setPerPage(newPerPage);
  };

  const tableHeaderstyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "var(--primary-color)",
        color: "#fff",
      },
    },
  };

  return (
    <div className="container ">
      <Helmet>
        <title>Product | Admin | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <div className="shadow border">
        <DataTable
          title="Products"
          customStyles={tableHeaderstyle}
          columns={columns}
          data={data.data}
          progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          fixedHeader
          highlightOnHover
          // onRowClicked={(val) => {
          //   navigate(`/admin/products/product-list/${val._id}`);
          // }}
        />
      </div>
    </div>
  );
};

export default ProductList;
