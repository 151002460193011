import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const ForbiddenError = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Go back one step in history
  };

  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h1 className="text-center">403 Forbidden</h1>
          <p className="text-center">Access to this resource is forbidden.</p>
          <div className="text-center">
            <Button variant="primary" onClick={handleGoBack}>Go Back</Button>
            <Button variant="primary" href="/" className="ms-2">Go to Home</Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ForbiddenError;
