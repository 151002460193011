import MyImage from "../../../../custom-components/MyImage";

const ProgressBar = ({ title, val }) => {
  return (
    <div className="mb-3">
      <div className="color-black font-bold mb-2"> {title}</div>
      <div className="progress">
        <div
          className="progress-bar"
          style={{ width: `${val}%` , borderRadius: "10px" }}
          role="progressbar"
          aria-valuenow="80"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    </div>
  );
};

const PeoplesChoice = ({ data }) => {
  return (
    <div className="row max-width mx-auto mt-5 pt-5 mb-5 pb-5 px-4 ">
      <div className="col-md-6 mt-2">
        <div className="d-flex align-items-start flex-column justify-content-center">
          <span className="font-color-primary font-bold">Why Choose Us</span>
          <h1>{data?.content?.title}</h1>
          <p className="fs-16 color-title-color">{data?.content?.paragraph}</p>
        </div>
        {data?.content?.progress_items?.map((item, index) => (
          <ProgressBar key={index} title={item?.label} val={item?.percentage} />
        ))}
      </div>
      <div className="col-md-6">
        <div className="mx-auto ">
          <div className="image-wrapper-2 mx-auto position-relative">
            {/* Main background image */}
            <MyImage
              src={data?.content?.large_image_url}
              alt="Hero Section"
              className="img-fluid rad-10"
            />
            {/* <div className="overlay2 rad-10"></div> */}

            {/* <MyImage
              src={data?.content?.small_image_url}
              alt="Hero Section"
              className="img-fluid why-choose-us-top-image "
              style={{ zIndex: 1 }} // Adjust size and z-index as needed
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default PeoplesChoice;
