import { encryptTransform } from 'redux-persist-transform-encrypt';

const encryptionConfig = {
  transforms: [
    encryptTransform({
      secretKey: process.env.REACT_APP_REDUX_PERSIST_ENCRYPTION_SECRET ?? 'my-super-secret-key-here',
      onError: function (error) {
        // Custom error handling
      },
    }),
  ],
};

export default encryptionConfig;
