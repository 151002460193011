import React from "react";
import MyImage from "../../../../custom-components/MyImage";

const HeroSectionAboutUs = ({ data }) => {
  return (
    <div className="row max-width mx-auto mt-5 pt-5 mb-5 pb-5 px-4 g-5 ">
      <div className="col-lg-6 ">
        <div className="pe-5">
          <div className=" mx-auto position-relative d-flex justify-content-center">
            <div className="top-display-bar d-flex justify-center align-center flex-column">
              <p className="about-us-time-count fw-semibold text-center m-0">
                {data?.content?.experience_time_count}
              </p>
              <p className="mb-2">{data?.content?.experience_time_content}</p>
            </div>
            <div className="about-us-hero-img">
              <MyImage
                src={data?.content?.background_image}
                alt=""
                className="rad-10 img-fluid "
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6 mt-2">
        <div className="d-flex align-items-start flex-column justify-content-center ps-2">
          <span className="font-color-primary font-bold">About Us</span>
          <h2 className="text-title fw-semibold py-2">
            {data?.content?.title}
          </h2>
          <p className="color-title-color pb-2">
            {data?.content?.paragraph}
          </p>
          <div className="row">
            <div className="col text-center">
              <div className="d-flex justify-content-between align-items-center border-end pe-2 gap-2 ">
                <div className="doctor-img">
                  <MyImage
                    src={data?.content?.founder_image_url}
                    alt=""
                    className="w-100"
                  />
                </div>
                <div className="d-flex flex-column">
                  <span>{data?.content?.founder_name}</span>
                  <span>{data?.content?.founder_designation}</span>
                </div>
              </div>
            </div>
            <div className="ps-3 col text-center d-flex justify-center align-center">
              <MyImage
                src={data?.content?.founder_signature_image_url}
                alt=""
                className="img-fluid mw-100 object-fit-contain"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSectionAboutUs;
