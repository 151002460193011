import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const CustomSwiper = ({ testimonial }) => {
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={200}
      slidesPerView={1}
      centeredSlides={true}
      autoplay={{
        delay: 2000,
        disableOnInteraction: false
      }}
      navigation={true}
      //   pagination={{ clickable: true }}
      //   scrollbar={{ draggable: true }}
      //onSwiper={(swiper) => console.log(swiper)}
    >
      {testimonial?.map((item) => (
        <SwiperSlide key={JSON.stringify(item)}>
          <div className="row align-items-center">
            <div className="col-lg-6 d-flex justify-content-center align-items-center mb-3">
              <img
                src={item?.image}
                className="testimonial-img img-fluid w-100 rounded-circle"
                alt="testimonial"
              />
            </div>
            <div className="col-lg-6">
              <div className="testimonial">
                <p className="text-center color-title-color">{item?.content}</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
const Testimonial = ({ data }) => {
  return (
    <div className=" bg-primary-color-light-2 mt-5 mb-5 pt-5 pb-5">

      <div className="container container-fluid py-4 px-4 d-flex justify-content-center flex-column align-items-center ">
        <div className="d-flex justify-content-center align-items-center flex-column mb-5">
          <div className="title-40 fw-bold mb-2 text-center">
            {data?.content?.title}
          </div>
          <div className="testimonial-sub color-title-color text-center">
            {data?.content?.subtitle}
          </div>
        </div>
        <CustomSwiper testimonial={data?.content?.testimonial_items} />
      </div>
      
    </div>
  );
};

/* <div className="d-flex justify-content-center align-items-center flex-column">
          <div className="testimonial-title fw-bold  font-family-secondary color-title-color mb-2">
            What Our Customer Saying..
          </div>
          <div className="testimonial-sub color-title-color">
            It is a long established fact that a reader will be distracted by.
          </div>
        </div> */

export default Testimonial;
