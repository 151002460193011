import React, { useEffect } from "react";
import {
  EyeOpenIcon,
  EyeCloseIcon,
  LockIcon,
} from "../../../../utils/WebIcons";

import usePasswordToggle from "../../../../hooks/ui-hooks/usePasswordToggle";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import useResetPassword from "../../../../hooks/data-hooks/auth/useResetPassword";

function ResetPassword() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    reset,
  } = useForm();
  const [getPasswordInputType, getPasswordIcon, togglePasswordVisibility] =
    usePasswordToggle();
  const { email, otp } = useSelector((state) => state.forgetPassword); // Get the email and otp state from the forget-password slice

  const {
    resetPassword,
    isResetLoading,
    isResetError,
    resetError,
    resetSubmitSuccess,
    resetSuccessResponse,
  } = useResetPassword(); // Use the useResetPassword hook

  useEffect(() => {
    // Show error toast if isError is true
    if (isResetError) {
      toast.error(resetError.message);
    }
  }, [isResetError, resetError]);

  useEffect(() => {
    // Show success toast if submitSuccess is true
    if (resetSubmitSuccess) {
      reset();
      toast.success(resetSuccessResponse.message);
    }
  }, [resetSubmitSuccess, resetSuccessResponse]);

  const onSubmit = async (data) => {
    // Prevent form submission if there is an ongoing sign-up process
    if (isSubmitting) return;

    try {
      // Call the custom hook for clinic sign-up
      resetPassword({ email, otp, password: data.password });
    } catch (error) {
      // Error handling is done in useEffect
    }
  };

  // Watch the password field to get its value
  const password = watch("password", ""); // Provide a default value in case the field is not found

  // Custom validation function to check if passwords match
  const validatePasswordMatch = (value) => {
    return value === password || "Passwords do not match";
  };

  return (
    <>
      {" "}
      <Helmet>
        <title>Reset Password | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <div className="auth-card px-4 px-sm-5 py-5 mt-sm-5">
        <div className="text-center mb-5">
          <div className="title-32 fw-bold mb-2">Reset your password</div>
          <div className="fs-18px">Your OTP is verified</div>
        </div>
        <form className="web-form mb-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-item">
            <label htmlFor="inputPassword" className="">
              Password
            </label>
            <div className="input-wrapper position-relative">
              <span className="position-absolute top-50 icon-start translate-middle-y">
                <LockIcon />
              </span>
              <input
                type={getPasswordInputType()}
                className=""
                placeholder="Enter password"
                {...register("password", {
                  required: "Password is required",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters long",
                  },
                })}
              />

              <span
                onClick={togglePasswordVisibility}
                className="cursor-pointer position-absolute top-50 icon-end translate-middle-y"
              >
                {getPasswordIcon() === "eye-open" ? (
                  <EyeOpenIcon />
                ) : (
                  <EyeCloseIcon />
                )}
              </span>
            </div>
            {errors.password && (
              <div className="text-danger">{errors.password.message}</div>
            )}
          </div>
          <div className="form-item">
            <label htmlFor="inputPassword" className="">
              Confirm Password
            </label>
            <div className="input-wrapper position-relative">
              <span className="position-absolute top-50 icon-start translate-middle-y">
                <LockIcon />
              </span>
              <input
                type={getPasswordInputType()}
                className=""
                placeholder="Confirm password"
                {...register("confirmPassword", {
                  required: "Confirm password is required",
                  validate: validatePasswordMatch, // Use the custom validation function here
                })}
              />

              <span
                onClick={togglePasswordVisibility}
                className="cursor-pointer position-absolute top-50 icon-end translate-middle-y"
              >
                {getPasswordIcon() === "eye-open" ? (
                  <EyeOpenIcon />
                ) : (
                  <EyeCloseIcon />
                )}
              </span>
            </div>
            {errors.confirmPassword && (
              <div className="text-danger">
                {errors.confirmPassword.message}
              </div>
            )}
          </div>

          <button
            type="submit"
            className="w-100 button-primary h-64px"
            disabled={isResetLoading}
          >
            {isResetLoading ? "Resetting..." : "Reset"}
          </button>
        </form>
      </div>
    </>
  );
}

export default ResetPassword;
