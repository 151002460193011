import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { StarIcon } from "../../../../utils/MyIcons";
import useLabDetails from "../../../../hooks/data-hooks/admin/lab-management/useLabDetails";
import { useParams } from "react-router-dom";
import MyImage from "../../../../custom-components/MyImage";
import { Str } from "@jimuelpalaca/str";
import ProfileBarShimmer from "../../../common/components/shimmers/ProfileBarShimmer";
import useGetOrderListOfLab from "../../../../hooks/data-hooks/admin/lab-management/useGetOrderListOfLab";
import DataTable from "react-data-table-component";
import Loader from "../../../common/components/loader/Loader";
import { Rating } from "react-simple-star-rating";

const AdminLabDetails = () => {
  const [totalRowsOrder, setTotalRowsOrder] = useState(0);
  const [totalRowsProduct, setTotalRowsProduct] = useState(0);

  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  let { id, name } = useParams();

  const {
    fetchLabDetailsWithId,
    isLoadingLabDetails,
    isError,
    labDetails,
    labDetailsError,
    productList,
    fetchProductListWithId
  } = useLabDetails(id, name, perPage, currentPage);
  const { fetchOrderListOfLab, isLoading, orderList } = useGetOrderListOfLab(
    name,
    perPage,
    currentPage
  );

  const [tabNum, setTabNum] = useState(1);

  useEffect(() => {
    setTotalRowsOrder(orderList?.meta?.pagination?.total_count);
  }, [orderList]);

  useEffect(() => {
    setTotalRowsProduct(productList?.meta?.pagination?.total_count);
  }, [productList]);
  // Slice data array based on current page and items per page
  // const paginatedData = clinicTotalOrderData.slice(
  //   currentPage * perPage,
  //   (currentPage + 1) * perPage
  // );

  // Function to handle page change
  // const handlePageClick = ({ selected }) => {
  //   setCurrentPage(selected);
  // };

  // -- real Logic

  // console.log(productList);

  useEffect(() => {
    fetchLabDetailsWithId(id, perPage, currentPage); // Fetch lab details when labId changes

    fetchProductListWithId(id, perPage, currentPage);
  }, [id, perPage, currentPage]);

  const tableHeaderstyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "#ccc"
      }
    }
  };

  const handlePageChange = (page) => {
    fetchProductListWithId(id, perPage, page);
    fetchOrderListOfLab(perPage, page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchProductListWithId(id, newPerPage, page);
    fetchOrderListOfLab(newPerPage, page);
    setCurrentPage(page);
    setPerPage(newPerPage);
  };

  const columnsOfOrderList = [
    {
      name: "#",
      selector: (row, index) =>
        (orderList?.meta?.pagination?.current_page - 1) * perPage + index + 1,
      sortable: true
    },
    {
      name: "Order Id",
      selector: (row) => row.orderId,
      sortable: true
    },
    {
      name: "Ordered By",
      selector: (row) => row.userId.email,
      sortable: true
    },

    {
      name: "Order Status",
      selector: (row) => row.orderStatus,
      sortable: true
    },
    {
      name: "Payment Status",
      selector: (row) => row.paymentStatus,
      sortable: true
    },
    {
      name: "Delivery Status",
      selector: (row) => row.deliveryStatus,
      sortable: true
    }
  ];

  const columnsOfProductList = [
    {
      name: "#",
      selector: (row, index) =>
        (productList?.meta?.pagination?.current_page - 1) * perPage + index + 1,
      sortable: true
    },
    {
      name: "Name",
      selector: (row) => row?.name,
      sortable: true
    },
    {
      name: "Price",
      selector: (row) => row?.stock,
      sortable: true
    },

    {
      name: "Discount",
      selector: (row) => row?.discount,
      sortable: true
    }
  ];

  if (isLoading || isLoadingLabDetails) return <Loader />;
  return (
    <div className="container ">
      <h5>
        Lab Profile : :{" "}
        {!isLoadingLabDetails && labDetails ? labDetails.labName : "Loading..."}
      </h5>

      <div className="d-flex flex-column flex-lg-row gap-3 ">
        {/* Profile */}
        <div className="bg-white rounded-3 p-4 w-lg-25 ">
          {(isLoadingLabDetails || isError) && <ProfileBarShimmer />}
          {!isLoadingLabDetails && !isError && labDetails && (
            <>
              <div className="text-center">
                <MyImage
                  src={labDetails.labImageUrl}
                  addBaseUrl={true}
                  alt=""
                  className="w-h-130px rounded-circle border border-5  border-primary-color my-3"
                />
                <div className="mt-2 vstack gap- ">
                  <span className="fw-bold text-title ">
                    {labDetails?.labName ?? "Name unavailable"}
                  </span>
                  <span className="fs-14 ">
                    {labDetails?.labPhoneNumber ?? "Phone number  unavailable"}
                  </span>
                  <span className="fs-14 ">
                    Status: {Str.snakeToWord(labDetails?.status ?? "unknown")}
                  </span>
                </div>
              </div>
              <hr />
              <div className="d-flex fs-12 ">
                <div className="d-flex flex-column w-50 gap-2 ">
                  <span>Followers</span>
                  <span>Clinic Ratings</span>
                  <span>Total Feedback</span>
                </div>
                <div className="d-flex flex-column gap-2">
                  <span>: 2.5 K</span>
                  <span>
                    : <Rating initialValue={2} readonly size={15} />
                    &nbsp;(2 (dummy))
                  </span>
                  <span>: 2167</span>
                </div>
              </div>
              <hr />
              <p className="fs-12">
                Your trusted destination for comprehensive healthcare, where
                wellness meets expertise for optimal health outcomes. Experience
                personalized care at <b>Core Well Clinic</b> , where our
                dedicated team combines advanced medical technology.
              </p>
              <hr />
              <p className="fs-12">
                121 South street, Dream holiday park, California <br />
                email: corewellclinic@email.com <br />
                Website: corewellclinic.com
              </p>
              <img
                src="/dummy-images/x-ray.jpg"
                alt=""
                className="w-100 rounded-3 "
              />
            </>
          )}
        </div>
        {/* Table */}
        <div className="flex-grow-1 ">
          <ul className="nav nav-tabs">
            <li className="nav-item" onClick={() => setTabNum(1)}>
              <a
                className={`nav-link ${tabNum === 1 && "active"}`}
                aria-current="page"
              >
                Order
              </a>
            </li>
            <li className="nav-item" onClick={() => setTabNum(2)}>
              <a className={`nav-link ${tabNum === 2 && "active"}`}>Product</a>
            </li>
          </ul>
          {/* Order Table */}
          <div className={`${tabNum !== 1 && "d-none"}`}>
            <h5 className="bg-primary-color p-4 text-white rounded-top-3 mb-0 ">
              Total orders information
            </h5>
            <div className="p-4 bg-white rounded-bottom-3 table-responsive vstack gap-4">
              <DataTable
                // title={headerComponent}
                customStyles={tableHeaderstyle}
                columns={columnsOfOrderList}
                data={orderList?.data}
                pagination
                paginationServer
                paginationTotalRows={totalRowsOrder}
                paginationDefaultPage={currentPage}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                fixedHeader
                selectableRowsHighlight
                highlightOnHover
              />
            </div>
          </div>
          {/* Product Table */}
          <div className={`${tabNum !== 2 && "d-none"}`}>
            <h5 className="bg-primary-color p-4 text-white rounded-top-3 mb-0 ">
              Total Product information
            </h5>
            <DataTable
              // title={headerComponent}
              customStyles={tableHeaderstyle}
              columns={columnsOfProductList}
              data={productList?.data}
              pagination
              paginationServer
              paginationTotalRows={totalRowsProduct}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              fixedHeader
              selectableRowsHighlight
              highlightOnHover
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdminLabDetails;
