import React from "react";

const HealthClinics = ({ data }) => {
  return (
    <div className="max-width mx-auto py-4 px-4 mt-5">
      <div className="hc-title font-family-secondary color-title-color text-center">
        <h1 className="fw-semibold">Get your Equipment at low prices from Health Clinics</h1>
      </div>
      <div className="container mt-5">
        <div className="row">
          {data?.content?.content_items?.map((item, index) => (
            <div key={index} className="col-lg-6 mb-4">
              <h5 className="hc-card-title font-family-secondary color-title-color mb-3">
                {item?.title}
              </h5>
              <p className="hc-card-desc">{item?.paragraph}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HealthClinics;
