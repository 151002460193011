import React from "react";
import { Helmet } from "react-helmet-async";
import Testimonial from "../testimonial/Testimonial";
import Partner from "../partner/Partner";
import PageHeader from "../page-header/PageHeader";
import Loader from "../../../common/components/loader/Loader";
import useWebContent from "../../../../hooks/data-hooks/website/useWebContent";
import HeroSectionAboutUs from "../hero-section-about-us/HeroSectionAboutUs";
import MemberStats from "../member-stats/MemberStats";
import PeoplesChoice from "../PeoplesChoice/PeoplesChoice";


const AboutUs = () => {
  const { isLoading, data } = useWebContent();

  if (isLoading) return <Loader />;
  return (
    <div className="w-100 bg-white">
      <Helmet>
        <title>About Us | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <PageHeader
        title={"About us"}
        header={"About Mena Medica"}
        subTitle={"Home / About"}
      />
      <HeroSectionAboutUs data={data?.data?.about} />
      <MemberStats
        data={data?.data?.service_count}
        serviceCard={data?.data?.service_card}
      />
      <PeoplesChoice data={data?.data?.why_choose} />
      <Testimonial data={data?.data?.testimonial} />

      <Partner data={data?.data?.brand?.content} />
    </div>
  );
};

export default AboutUs;
