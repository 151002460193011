export const statsData = [
  {
    id: 1,
    title: "Total Lab",
    sales: "$242.99K",
    duration: "From running month"
  },
  {
    id: 2,
    title: "Total product",
    sales: "$192.99K",
    duration: "This year"
  },
  {
    id: 3,
    title: "Selling product",
    sales: "$10,622.99K",
    duration: "This year"
  },
  {
    id: 4,
    title: "Total User",
    sales: "10,991K",
    duration: "Running month"
  }
];

export const topSellingProductData = [
  {
    id: 1,
    item: "Dental X-ray",
    rating: 4,
    sales: 1237,
    image: "/dummy-images/x-ray.jpg"
  },
  {
    id: 2,
    item: "Dental Laser",
    rating: 4,
    sales: 1021,
    image: "/dummy-images/product-2.png"
  },
  {
    id: 3,
    item: "Dental Explorer",
    rating: 5,
    sales: 938,
    image: "/dummy-images/product-3.png"
  },
  {
    id: 4,
    item: "Dental Chair",
    rating: 5,
    sales: 5611,
    image: "/dummy-images/product-4.png"
  },
  
];

export const bestLabSellersData = [
  {
    id: 1,
    logo: "/dummy-images/x-ray.jpg",
    name: "Confident Lab",
    sell: 1000
  },
  {
    id: 2,
    logo: "/dummy-images/product-2.png",
    name: "Precision Smile Lab",
    sell: 1300
  },
  {
    id: 3,
    logo: "/dummy-images/product-3.png",
    name: "Elite Dental Craft",
    sell: 1060
  },
  {
    id: 4,
    logo: "/dummy-images/product-4.png",
    name: "Smile Crafters Lab",
    sell: 1299
  },
  {
    id: 5,
    logo: "/dummy-images/product-4.png",
    name: "Perfect Fit Dental Lab",
    sell: 2500
  }
];
export const topClinicData = [
  {
    id: 1,
    logo: "/dummy-images/x-ray.jpg",
    name: "Confident Lab",
    sell: 1000
  },
  {
    id: 2,
    logo: "/dummy-images/product-2.png",
    name: "Precision Smile Lab",
    sell: 1300
  },
  {
    id: 3,
    logo: "/dummy-images/product-3.png",
    name: "Elite Dental Craft",
    sell: 1060
  },
  {
    id: 4,
    logo: "/dummy-images/product-4.png",
    name: "Smile Crafters Lab",
    sell: 1299
  }
];

export const userInfoData = [
  {
    id: 1,
    customerId: 20235093,
    image: "/dummy-images/x-ray.jpg",
    customerName: "Imtiaj Ahmed",
    phoneNumber: "+555-235093",
    membership: "Regular"
  },
  {
    id: 2,
    customerId: 20221090,
    image: "/dummy-images/product-2.png",
    customerName: "Simran Jemi",
    phoneNumber: "+555-221090",
    membership: "VIP"
  },
  {
    id: 3,
    customerId: 20204803,
    image: "/dummy-images/product-3.png",
    customerName: "Karan Sharma",
    phoneNumber: "+443-256340",
    membership: "Regular"
  },
  {
    id: 4,
    customerId: 20202697,
    image: "/dummy-images/product-4.png",
    customerName: "Yousuf Ahmed",
    phoneNumber: "+765-562697",
    membership: "VIP"
  }
];


/* ----------------------------------
  custom dummy data -------------
  createdBy - [fuad]---------
*/
export const messageItems = [
  {
    id: 1,
    orderId: 20235093,
    image: "https://i.ibb.co/fCMt42S/profile.png",
    lastTime: "10:00",
    orderDescription: "Regular | lerev ipsum sogu lorem",
    messageData: [
      { id: 1, chat: "20235093: Hello, how are you?", date:"January 12th, 2011"},
      // { id: 2, chat: "20235093: check, i am fine", date:"January 12th, 2011"},
      // { id: 3, chat: "20235093: whatsup", date:"January 12th, 2011"}
    ]
  },
  {
    id: 2,
    orderId: 50221090,
    image: "https://i.ibb.co/fCMt42S/profile.png",
    customerName: "Simran Jemi",
    lastTime: "6:00",
    orderDescription: "VIP | lorem ipsum sogu lorem",
    messageData: [
      { id: 1, chat: "50221090: Hello, how are you?", date:"February 27th, 2011"},
      // { id: 2, chat: "50221090: check, i am fine", date:"February 12th, 2011"},
      // { id: 3, chat: "50221090: whatsup", date:"February 12th, 2011"}
    ]

  },
  {
    id: 3,
    orderId: 20204803,
    image: "https://i.ibb.co/fCMt42S/profile.png",
    lastTime: "8:30",
    orderDescription: "Regular | lorem ipsum sogu lorem",
    messageData: [
      { id: 1, chat: "20204803: Hello, how are you?", date:"March 12th, 2011"},
      // { id: 2, chat: "20204803: check, i am fine", date:"March 12th, 2011"},
      // { id: 3, chat: "20204803: whatsup", date:"March 12th, 2011"}
    ]
  },
  {
    id: 4,
    orderId: 20202697,
    image: "https://i.ibb.co/fCMt42S/profile.png",
    customerName: "Simran Jemi",
    lastTime: "5:00",
    orderDescription: "VIP | saga ipsu slor chim",
    messageData: [
      { id: 1, chat: "20202697: Hello, how are you?", date:"April 12th, 2011"},
      // { id: 2, chat: "20202697: check, i am fine", date:"April 12th, 2011"},
      // { id: 3, chat: "20202697: whatsup", date:"April 12th, 2011"}
    ]
  }
];
// -----------------------------
