import React from "react";
import useGetUserInfoSection from "../../../../hooks/data-hooks/admin/dashboard/useGetUserInfoSection";

const UserInfo = () => {
  const { data1, isLoading1, isError1 } = useGetUserInfoSection();

  return (
    <div className="bg-white border rounded-4 vstack gap-2 px-5 py-30 h-100 table-responsive">
      <span className="text-title fw-900 fs-5 ps-2">User Info</span>
      <div className="table-container custom-scroll">
        <table className="table table-borderless">
          <thead className="table-header">
            <tr>
              <th scope="col">No</th>
              <th scope="col">Customer Id</th>
              <th scope="col">Customer Name</th>
              <th scope="col">Phone Number</th>
              <th scope="col">Membership</th>
            </tr>
          </thead>
          <tbody className="table-body">
            {data1?.data?.map((data, index) => {
              return (
                <tr key={index}>
                  <td className="text-text fs-14">{index + 1}</td>
                  <td className="text-text fs-14">{data?._id}</td>
                  <td className="text-text fs-14">
                    <img
                      src={data?.profileImageUrl}
                      alt=""
                      className="w-h-24px rounded-circle me-2"
                    />
                    {data?.fullName}
                  </td>
                  <td className="text-text fs-14">{data?.phoneNumber}</td>
                  <td className="text-text fs-14">{data?.status}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserInfo;

// import { userInfoData } from "../../../../dummy-data/admin/pages/dashboard/dummy-data";
// const UserInfo = () => {
//   return (
//     <div className="bg-white border rounded-4   vstack gap-2 px-5 py-30 h-100 table-responsive ">
//       <span className="text-title fw-900 fs-5 ps-2">User Info</span>
//       <table className="table table-borderless ">
//         <thead>
//           <tr>
//             <th scope="col">No</th>
//             <th scope="col">Customer Id</th>
//             <th scope="col">Customer Name</th>
//             <th scope="col">Phone Number</th>
//             <th scope="col">Membership</th>
//           </tr>
//         </thead>
//         <tbody>
//           {userInfoData.map((data, index) => {
//             return (
//               <tr key={index}>
//                 <td className="text-text fs-14">{data.id}</td>
//                 <td className="text-text fs-14">{data.customerId}</td>
//                 <td className="text-text fs-14">
//                   <img
//                     src={data.image}
//                     alt=""
//                     className="w-h-24px rounded-circle me-2   "
//                   />
//                   {data.customerName}
//                 </td>
//                 <td className="text-text fs-14">{data.phoneNumber}</td>
//                 <td className="text-text fs-14">{data.membership}</td>
//               </tr>
//             );
//           })}
//           {/* <tr>
//             <th scope="row">1</th>
//             <td>Mark</td>
//             <td>Otto</td>
//             <td>@mdo</td>
//           </tr> */}
//         </tbody>
//       </table>
//     </div>
//   );
// };
// export default UserInfo;
