import { MessageIcon } from "../../../../utils/MyIcons";

const MessageBadge = ({ messageCount, onClick }) => {
  let displayCount = messageCount;

  if (messageCount < 10) {
    displayCount = `${messageCount}`; // Add leading zero if message count is less than 10
  } else if (messageCount > 99) {
    displayCount = "99+"; // Display 99+ if message count is more than 99
  }
  return (
    <div className="position-relative d-inline-block" onClick={onClick}>
      <MessageIcon />
      {messageCount !== 0 && (
        <span className="message-badge">{displayCount}</span>
      )}
    </div>
  );
};

export default MessageBadge;
