import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import MyImage from "../../../../custom-components/MyImage"; //for image
import Loader from "../../../common/components/loader/Loader"; //for loader

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import noImageAvailable from "../../../../assets/image/placeholder-images/no-image-available.png";

// Custom Swip Mini:
const CustomSwiperMini = ({ data, setIndex }) => {
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={10}
      slidesPerView={1}
      centeredSlides={true}
      autoplay={{
        delay: 1000,
        disableOnInteraction: false,
      }}
      // navigation={true}
      // pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      //onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log("slide change")}
    >
      {data?.map((item, i) => (
        <SwiperSlide key={JSON.stringify(item)}>
          <div className=" d-flex justify-content-center align-items-center my-2">
            <MyImage
              src={item}
              addBaseUrl={true}
              className=" w-h-50px-max-min border rounded-3 cursor-pointer"
              alt="order_image"
              onClick={() => setIndex(i)}
            />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

const ProductDetailsInfo = ({ productInfo, isLoading }) => {
  // add state
  const [index, setIndex] = useState(0);

  const {
    name,
    description,
    categoryId,
    stock,
    price,
    discount,
    labId,
    images,
  } = productInfo;

  // console.log("images:", images);

  if (isLoading) return <Loader />;

  // console.log(productInfo?.images?.length);

  return (
    <div className="container ">
      <div className="row g-4 ">

        <div className="col-lg-6   ">
          <div className="border border-1 rounded-3 p-4 bg-highlight">
            <div className="mb-4">
              <h5 className="color-title-color fw-bold ">Item Name</h5>
              <span className="fs-16">{name}</span>
            </div>
            <div className="mb-4">
              <h5 className="color-title-color fw-bold ">Description</h5>
              <p>{description}</p>
            </div>
            <div className="mb-4">
              <h5 className="color-title-color fw-bold ">Category</h5>
              <span>{categoryId?.name}</span>
            </div>
            <div className="mb-4">
              <h5 className="color-title-color fw-bold ">Stock</h5>
              <span>{stock}</span>
            </div>
            <div className="mb-4">
              <h5 className="color-title-color fw-bold ">Price</h5>
              <span>${price}</span>
            </div>
            <div className="mb-4">
              <h5 className="color-title-color fw-bold ">Discount</h5>
              <span>{discount}%</span>
            </div>
          </div>
        </div>

        <div className="col-lg-6 d-flex flex-column   align-items-center">
          <div className="py-4 px-4 d-flex justify-content-center flex-column align-items-center w-300px">
            <MyImage
              src={productInfo?.images ? productInfo?.images[index] : null}
              addBaseUrl={true}
              alt=""
              noImage={productInfo?.images?.length === 0}
              className="w-h-250px rounded-3 border"
            />

            <CustomSwiperMini data={images} setIndex={setIndex} />
          </div>

          <div className="d-flex justify-content-between gap-5 fs-16px  ">
            <div className="mb-4 bg-highlight border p-2 rounded-3">
              <span className="color-title-color fw-bold ">Total Orders: </span>
              <span>123</span>
              {/* <span>{orderCount }</span> */}
            </div>
            <div className="mb-4 bg-highlight border p-2 rounded-3">
              <span className="color-title-color fw-bold ">Rating: </span>
              <span>4.7</span>
              {/* <span>{rating}</span> */}
            </div>
            <div className="mb-4 bg-highlight border p-2 rounded-3">
              <span className="color-title-color fw-bold ">Lab: </span>
              <Link
                to={`/admin/users/lab/details/${labId?._id}`}
                className="link-style"
              >
                {labId?.labName}
              </Link>
              {/* <Link className="link-style">{labName}</Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductDetailsInfo;
