import React, { useState } from "react";
import { getFullImagePath } from "../helpers/imageHelper";

const MyImage = ({
  src,
  addBaseUrl = false,
  alt,
  className,
  style,
  noImage,
  ...rest
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const handleLoad = () => {
    setLoading(false);
  };

  const handleError = () => {
    setLoading(false);
    setError(true);
  };

  src = addBaseUrl ? getFullImagePath(src) : src;

  return (
    <img
      src={
        error || noImage
          ? "https://placehold.co/600x400?text=Image\n+not+Found"
          : loading
          ? "https://placehold.co/600x400?text=loading..."
          : src
      }
      alt={error ? "Error" : alt}
      className={className}
      style={style}
      onLoad={handleLoad}
      onError={handleError}
      {...rest}
    />
  );
};

export default MyImage;
