// useGetProductDetailsById.js
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import authPrefixedRoutes from "../../../../routes/all-routes/authRoutes";
import { useNavigate } from "react-router-dom";

const useGetProductDetailsById = (productId) => {
  const token = useSelector((state) => state.auth.user.accessToken);

  const [productDetails, setProductDetails] = useState({});
  const [productDetailsError, setProductDetailsError] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const fetchProductCategory = useQuery({
    queryKey: ["getproductdetailbyid"],
    queryFn: async () => {
      try {
        setIsLoading(true);
        const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/admin/product-management/product/details/${productId}`;
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        });

        if (!response.ok) {
          const text = await response.json();
          if (text?.error?.status === 401) {
            navigate(authPrefixedRoutes.LOGIN);
          }
          const text1 = await response.text();
          setProductDetailsError(text1);
          setIsLoading(false);
          throw new Error("Failed to fetch clinic details");
        }

        const responseData = await response.json();
        // console.log(responseData);
        setProductDetails(responseData?.data); // Assuming responseData contains product details
        setIsLoading(false);

        return responseData;
      } catch (error) {
        setProductDetailsError(error);
        setIsLoading(false);
        throw error;
      }
    },
    onError: (error) => {
      setProductDetailsError(error);
      setIsLoading(false);
      throw error;
    }
  });

  const fetchProductDetailWithId = (clinicId) => {
    fetchProductCategory.refetch(clinicId);
  };

  return {
    fetchProductDetailWithId,
    isLoading,
    productDetailsError,
    productDetails
  };
};

export default useGetProductDetailsById;
