import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { UserIcon } from "../../../../utils/WebIcons";

import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import useForgetPassword from "../../../../hooks/data-hooks/auth/useForgetPassword";
import { useForm } from "react-hook-form";

// import { authPrefixedRoutes } from "../../../../routes/all-routes/authRoutes";

function ForgetPassword() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    reset
  } = useForm();

  const {
    forgetPassword,
    isLoading,
    isError,
    forgetPasswordError,
    submitSuccess,
    forgetPasswordSuccessResponse
  } = useForgetPassword();

  useEffect(() => {
    // Show error toast if isError is true
    if (isError) {
      toast.error(forgetPasswordError.message);
    }
  }, [isError, forgetPasswordError]);

  useEffect(() => {
    // Show success toast if submitSuccess is true
    if (submitSuccess) {
      reset();
      toast.success(forgetPasswordSuccessResponse.message);
    }
  }, [submitSuccess, forgetPasswordSuccessResponse]);

  const onSubmit = async (data) => {
    // Prevent form submission if there is an ongoing sign-up process
    if (isSubmitting) return;

    try {
      // Call the custom hook for forgetPassword
      forgetPassword(data);
      // console.log(data.email);
    } catch (error) {
      // Error handling is done in useEffect
    }
  };

  return (
    <>
      <Helmet>
        <title>Forgot Password | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <div className="auth-card px-4 px-sm-5 py-5 mt-sm-5">
        <div className="text-center mb-5">
          <div className="title-32 fw-bold mb-2">Forgot your password ?</div>
          <div className="fs-18px">We will send an OTP to you</div>
        </div>
        <form className="web-form mb-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-3 position-relative">
            <label htmlFor="inputEmail" className="">
              Email address
            </label>
            <div className="input-wrapper position-relative">
              <span className="position-absolute top-50 icon-start translate-middle-y">
                <UserIcon />
              </span>
              <input
                type="email"
                className=""
                placeholder="Enter your email"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: "Invalid email address"
                  }
                })}
              />
            </div>
          </div>

          <button type="submit" className="w-100 button-primary h-64px">
            {isLoading ? "Submitting..." : "Send"}
          </button>
        </form>
        <div className="text-center">
          <div>
            Remember your password ?{" "}
            <NavLink
              to={process.env.REACT_APP_LAB_CLINIC_PANEL_URL} //
              className="text-decoration-none font-color-primary font-bold"
            >
              Login
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgetPassword;
