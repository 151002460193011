import TransactionCard from "./TransactionCard";
import img1 from "../../../../dummy-img/transactionImg-1.png";
import img2 from "../../../../dummy-img/transactionImg-2.png";
import img3 from "../../../../dummy-img/transactionImg-3.png";

const TransactionHistory = (data) => {
  const { totalTransaction, totalDue, totalReceivedAmount } = data.data;
  return (
    <div className="row g-2">
      <TransactionCard
        title="Total transactions"
        amount={totalTransaction}
        time="This month"
        image={img1}
      />
      <TransactionCard
        title="Total due"
        amount={totalDue}
        time="This month"
        image={img3}
      />
      <TransactionCard
        title="Total received"
        amount={totalReceivedAmount}
        time="This month"
        image={img2}
      />
    </div>
  );
};
export default TransactionHistory;
