import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import useTimer from "../../../../hooks/ui-hooks/useTimer";
import "../../../../styles/outerPanel.css";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import useVerifyForgetPasswordOtp from "../../../../hooks/data-hooks/auth/useVerifyForgetPasswordOtp";
import useResendForgetPasswordOtp from "../../../../hooks/data-hooks/auth/useResendForgetPasswordOtp";
import { useForm } from "react-hook-form";

// Function to format the timer display
const formatTimer = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
};

function ForgetPasswordOtp() {

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm();
  const [otp, setOtp] = useState("");
  const [showResendButton, setShowResendButton] = useState(false);

  const { email } = useSelector((state) => state.forgetPassword); // Get the email state from the forget-password slice

  const {
    verifyForgetPasswordOtp,
    isVerifyLoading,
    isVerifyError,
    verifyError,
    verifySubmitSuccess,
    verifySuccessResponse
  } = useVerifyForgetPasswordOtp(); // Use the useVerifyForgetPasswordOtp hook

  const {
    resendForgetPasswordOtp,
    isResendLoading,
    isResendError,
    resendError,
    resendSubmitSuccess,
    resendSuccessResponse
  } = useResendForgetPasswordOtp(); // Use the useResendForgetPasswordOtp hook

  // useEffect(() => {
  //   if (!hasSignedUp) {
  //     // If hasSignedUp is false, redirect to the forbidden page after a timeout of 1500 milliseconds
  //     const forbiddenNavTimeOut = setTimeout(() => {
  //       navigate(commonRoutes.FORBIDDEN);
  //     }, 1500);

  //     // Show a toast message indicating redirection
  //     toast.info(
  //       "You are not allowed to access this page. Redirecting to Forbidden page..."
  //     );

  //     // Clear the timeout when the component unmounts or hasSignedUp changes
  //     return () => clearTimeout(forbiddenNavTimeOut);
  //   }
  //   // }, [navigate, hasSignedUp]);
  // }, []);

  // Function to handle resend OTP
  const handleResend = () => {
    if (isResendLoading) {
      return;
    }
    if (!email) {
      toast.error("Email not found !");
    }
    resendForgetPasswordOtp({ email });
  };

  useEffect(() => {
    // Show error toast if isError is true
    if (isResendError) {
      resetTimer();
      startTimer();
      setShowResendButton(false); // Hide the resend button

      toast.error(resendError.message);
    }
  }, [isResendError, resendError]);

  useEffect(() => {
    // Show success toast if submitSuccess is true
    if (resendSubmitSuccess) {
      resetTimer();
      startTimer();
      setShowResendButton(false); // Hide the resend button

      toast.success(resendSuccessResponse.message);
    }
  }, [resendSubmitSuccess, resendSuccessResponse]);

  const { seconds, isActive, startTimer, resetTimer } = useTimer(
    120,
    1000,
    () => setShowResendButton(true) // Show the resend button when timer ends
  );

  useEffect(() => {
    // Auto-start the timer when component loads
    startTimer();
  }, [startTimer]);

  useEffect(() => {
    // Show error toast if isError is true
    if (isVerifyError) {
      toast.error(verifyError.message);
    }
  }, [isVerifyError, verifyError]);

  useEffect(() => {
    // Show success toast if submitSuccess is true
    if (verifySubmitSuccess) {
      toast.success(verifySuccessResponse.message);
    }
  }, [verifySubmitSuccess, verifySuccessResponse]);

  const onSubmit = (data) => {
    verifyForgetPasswordOtp({ email, otp: data.otp });
  };

  const handlePaste = (event) => {
    const data = event.clipboardData.getData("text");
    // Update the OTP state
    setOtp(data);
    // Update the form value for the OTP field
    setValue("otp", data);
  };

  return (
    <div className="container otp-container mx-auto ">
      <div className="otp-box px-sm-4">
        <div className="otp-inner-box">
          <div className="title-40 mb-2 text-center">Forget Password OTP</div>
          <div className="fs-16px text-center">
            Please enter the OTP (One-Time Password) sent to your provided
            email.
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-5  mb-5">
              <OtpInput
                className=""
                placeholder="******"
                numInputs={6}
                shouldAutoFocus={true}
                renderSeparator={<span className="px-1 px-sm-2"></span>}
                renderInput={(props) => <input {...props} />}
                value={otp}
                onPaste={handlePaste}
                onChange={(otp) => {
                  setOtp(otp);
                  setValue("otp", otp);
                }}
              />
              <input
                type="hidden"
                name="otp"
                {...register("otp", { required: true, minLength: 6 })}
              />
            </div>
            <div className="d-flex justify-content-center">
              {errors.otp && (
                <span className="text-danger">Please enter a valid OTP</span>
              )}
            </div>
            <div className="d-flex justify-content-center">
              <button
                className="button-primary h-52px mb-2"
                type="submit"
                disabled={isVerifyLoading}
              >
                {isVerifyLoading ? "Verifying..." : "Verify"}
              </button>
            </div>
          </form>

          <div className="fs-16x mb-4">Resend In {formatTimer(seconds)}</div>
          {showResendButton && (
            <span
              className="text-decoration-underline cursor-pointer"
              onClick={handleResend}
              disabled={isResendLoading} // Disable the button when resend is loading
            >
              Resend OTP
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default ForgetPasswordOtp;
