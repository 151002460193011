// useGetTestimonialSection.js
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { commonRoutes } from "../../../../../routes/all-routes/commonRoutes";
import { useSelector } from "react-redux";
import authPrefixedRoutes from "../../../../../routes/all-routes/authRoutes";

const useGetTestimonialSection = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.user.accessToken);
  const [testimonialContent, setTestimonialContent] = useState();
  const [testimonialPlaceholder, setTestimonialPlaceHolder] = useState();

  const { isLoading, isError, data } = useQuery({
    queryKey: ["testimonialSection"],
    queryFn: async () => {
      //  dispatch(setLoading(true));

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/admin/website-content/home-page/testimonial-section`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (!response.ok) {
        const text = await response.json();
        if (text?.error?.status === 401) {
          navigate(authPrefixedRoutes.LOGIN);
        }
        // dispatch(setError("Failed to fetch data"));
        navigate(commonRoutes.SERVER_ERROR); // Navigate to error500 page
        return;
      }
      const data = await response.json();
      setTestimonialContent(data?.data?.content);
      setTestimonialPlaceHolder(data?.data?.placeholder);
      return data;
    }
  });

  const handleRemovePair = (imageIndex, image) => {
    setTestimonialContent({
      ...testimonialContent,
      testimonial_items: testimonialContent?.testimonial_items.filter(
        (item, index) => index !== imageIndex && item?.image !== image
      )
    });
  };

  return {
    isLoadingGet: isLoading,
    isError1: isError,
    testimonialContent,
    testimonialPlaceholder,
    handleRemovePair,
    data1: data,
  };
};

export default useGetTestimonialSection;
