const BestLabSellerItem = ({ data }) => {
  const { logo, name, sell } = data;
  return (
    <div className="d-flex  align-items-center justify-content-between ">
      <div className="d-flex align-items-center gap-1 ">
        <img src={logo} alt="" className="w-h-33px rounded-circle " />
        <span>{name}</span>
      </div>
      <span>${sell}</span>
    </div>
  );
};
export default BestLabSellerItem;
