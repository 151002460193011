import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
// Importing custom hook for API call
import useGetFooterSection from "../../../../hooks/data-hooks/admin/common/footer-section/useGetFooterSection";
import useUpdateFooterSection from "../../../../hooks/data-hooks/admin/common/footer-section/useUpdateFooterSection";
import Button from "../../../web/components/button/Button";
import Loader from "../../../common/components/loader/Loader";

const FooterSection = () => {
  const {
    footerContent,
    isLoading1,
    isError1,
    handleRemovePair,
    handleUpdateFooterContent,
    data,
  } = useGetFooterSection();

  const [inputPairs, setInputPairs] = useState([0, 0, 0]);

  useEffect(() => {
    handleUpdateFooterContent();
  }, [data]);

  useEffect(() => {
    if (footerContent) {
      const pairs = [
        footerContent.section_one.links.length,
        footerContent.section_two.links.length,
        footerContent.section_three.links.length,
      ];
      setInputPairs(pairs);
    }
  }, [footerContent]);

  const handleAddPair = (index) => {
    setInputPairs((prevPairs) => {
      const newPairs = [...prevPairs];
      newPairs[index] += 1;
      return newPairs;
    });
  };

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    setValue,
  } = useForm();

  useEffect(() => {
    if (footerContent) {
      ["section_one", "section_two", "section_three"].forEach((section) => {
        if (footerContent[section]) {
          setValue(`${section}.title`, footerContent[section].title);
          footerContent[section].links.forEach((item, index) => {
            setValue(`${section}.links[${index}].label`, item.label);
            setValue(`${section}.links[${index}].url`, item.url);
          });
        }
      });
    }
  }, [footerContent, setValue]);

  const { updateContent, isLoading, isError, error, submitSuccess } =
    useUpdateFooterSection();

  useEffect(() => {
    // Show error toast if isError is true
    if (isError) {
      toast.error(error.message);
    }
  }, [isError, error]);

  // useEffect(() => {
  //   // Show success toast if submitSuccess is true
  //   if (submitSuccess) {
  //     toast.success("Form Submit Successful");
  //   }
  // }, [submitSuccess]);

  const onSubmit = async (data) => {
    if (isSubmitting) return;

    const formattedData = [
      "section_one",
      "section_two",
      "section_three",
    ].reduce((acc, section) => {
      acc[section] = {
        ...data[section],
        links: data[section]?.links.filter(
          (item) => item?.label !== "" || item?.url !== ""
        ),
      };
      return acc;
    }, {});

    // Prevent form submission if there is an ongoing login process

    try {
      // Call the custom hook for API call
      updateContent(formattedData);
    } catch (error) {
      // Error handling is done in useEffect
    }
  };

  if (isLoading1) return <Loader />;

  const renderSection = (section, index) => (
    <div className={`footer-section-${index + 1} mb-3`} key={index}>
      <span className="fs-18px fw-bold mb-3">Section {index + 1}</span>
      <div className="form-item">
        <label>Section Title</label>
        <input
          type="text"
          placeholder={`Enter ${section} title here`}
          {...register(`${section}.title`, {
            required: "Field is required",
          })}
        />
      </div>
      {Array.from({ length: inputPairs[index] }).map((_, pairIndex) => (
        <div key={pairIndex}>
          <div className="form-item">
            <div className="d-flex justify-content-between">
              <label>Label {pairIndex + 1}</label>
              <button
                type="button"
                className="border-0 px-2"
                onClick={() => {
                  handleRemovePair(
                    section,
                    pairIndex,
                    footerContent?.[section]?.links[pairIndex]?.label
                  );
                  setInputPairs((prevPairs) => {
                    const newPairs = [...prevPairs];
                    newPairs[index] -= 1;
                    return newPairs;
                  });
                  setValue(`${section}.links[${pairIndex}].label`, "");
                  setValue(`${section}.links[${pairIndex}].url`, "");
                }}
              >
                X
              </button>
            </div>
            <input
              rows={5}
              placeholder="Enter label here"
              {...register(`${section}.links[${pairIndex}].label`, {
                required: "Field is required",
              })}
            />
          </div>
          <div className="form-item">
            <label>URL - {pairIndex + 1}</label>
            <input
              rows={5}
              placeholder="Enter URL here"
              {...register(`${section}.links[${pairIndex}].url`, {
                required: "Field is required",
              })}
            />
          </div>
          <hr />
        </div>
      ))}
      <button
        type="button"
        className="w-25 button-primary p-2 "
        onClick={() => handleAddPair(index)}
      >
        Add More
      </button>
      <hr />
    </div>
  );

  return (
    <div className="cms-section ">
      <div className="cms-card  ">
        <form className="cms-form mb-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="fs-18px fw-bold mb-3">Footer Section</div>
          <hr />

          <div className="cms-content custom-scroll">
            {["section_one", "section_two", "section_three"].map(
              (section, index) => renderSection(section, index)
            )}
          </div>

          <Button
            label={isLoading ? "Submitting" : "Submit"}
            type="submit"
            className={`w-100  ${isLoading ? "bg-disabled" : ""}`}
            disabled={isLoading}
          />
        </form>
      </div>
    </div>
  );
};
export default FooterSection;
