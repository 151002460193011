import "../../../../styles/outerPanel.css";
import SiteLogoContainer from "../../../common/components/section/SiteLogoContainer";
import { AuthPageSvg } from "../../../../utils/WebIcons";
import { useNavigate } from "react-router-dom";

const AuthLayout = ({ children }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="container mx-auto px-3 px-md-0 mt-4">
        <div onClick={() => navigate("/")}>
          <SiteLogoContainer logoColor="black" />
        </div>
      </div>

      <div className="container mx-auto px-3 px-md-0 auth-page-container">
        <div className="d-flex align-items-center justify-content-evenly">
          <span className="d-none d-lg-block">
            <AuthPageSvg />
          </span>
          <div className="">{children}</div>
        </div>
      </div>
    </>
  );
};

export default AuthLayout;
