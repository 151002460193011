// useGetFaqSection.js
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { commonRoutes } from "../../../../../routes/all-routes/commonRoutes";
import { useSelector } from "react-redux";
import authPrefixedRoutes from "../../../../../routes/all-routes/authRoutes";

const useGetFaqSection = () => {
  const navigate = useNavigate(); // React Router v6's navigate function
  const token = useSelector((state) => state.auth.user.accessToken);
  const [faqContent, setFaqContent] = useState();
  const [faqPlaceholder, setFaqPlaceHolder] = useState();

  const { isLoading, isError, data } = useQuery({
    queryKey: ["faqSection"],
    queryFn: async () => {
      //  dispatch(setLoading(true));
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/admin/website-content/home-page/faq-section`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (!response.ok) {
        const text = await response.json();
        if (text?.error?.status === 401) {
          navigate(authPrefixedRoutes.LOGIN);
        }
        // dispatch(setError("Failed to fetch data"));
        navigate(commonRoutes.SERVER_ERROR); // Navigate to error500 page
        return;
      }
      const data = await response.json();
      setFaqContent(data?.data?.content);
      setFaqPlaceHolder(data?.data?.placeholder);
      return data; // Return the fetched data
    }
  });

  const handleUpdateFaqContent = () => setFaqContent(data?.data?.content);

  const handleRemovePair = (questionIndex, question) => {
    setFaqContent({
      ...faqContent,
      faq_items: faqContent?.faq_items.filter(
        (item, index) => index !== questionIndex && item?.question !== question
      )
    });
  };

  return {
    data,
    isLoading1: isLoading,
    isError1: isError,
    faqContent,
    faqPlaceholder,
    handleRemovePair,
    handleUpdateFaqContent
  };
};

export default useGetFaqSection;
