import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { setForgetPasswordDataOtp } from "../../../redux/slices/forgetPasswordSlice";
import { useDispatch } from "react-redux";
import authPrefixedRoutes from "../../../routes/all-routes/authRoutes";
import { useNavigate } from "react-router-dom";
import { commonRoutes } from "../../../routes/all-routes/commonRoutes";

const useVerifyForgetPasswordOtp = () => {
  const dispatch = useDispatch();
  const [verifyError, setVerifyError] = useState(null);
  const [verifySubmitSuccess, setVerifySubmitSuccess] = useState(false);
  const [verifySuccessResponse, setVerifySuccessResponse] = useState(null);

  const navigate = useNavigate();

  const {
    mutate: verifyForgetPasswordOtp,
    isLoading: isVerifyLoading,
    isError: isVerifyError,
  } = useMutation({
    mutationKey: "verifyForgetPasswordOtp",
    mutationFn: async (formData) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/auth/forgot-password/validate-otp`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        const data = await response.json();

        if (!response.ok) {
          const text = await response.json();
          if (text?.error?.status === 401) {
            navigate(authPrefixedRoutes.LOGIN);
          }
          setVerifyError(data.error);
          throw new Error(data.error.message);
        }

        setVerifySubmitSuccess(true);
        setVerifySuccessResponse(data);
        dispatch(setForgetPasswordDataOtp({email: formData.email, otp: formData.otp }));

        // clear data so that , user cannot use the verification screen without email
        // dispatch(clearForgetPasswordData());

        // Delay navigation for 1 second
        setTimeout(() => {
          // navigate to reset password
          navigate(commonRoutes.RESET_PASSWORD);
        }, 1500);

        return data;
      } catch (error) {
        setVerifyError(error);
        throw error;
      }
    },
    onError: (error) => {
      setVerifyError(error);
      throw error;
    },
  });

  return {
    verifyForgetPasswordOtp,
    isVerifyLoading,
    isVerifyError,
    verifyError,
    verifySubmitSuccess,
    verifySuccessResponse,
  };
};

export default useVerifyForgetPasswordOtp;
