// AdminRouter.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Import Auth components
import AuthLayout from '../modules/auth/components/layout/AuthLayout';
import { authRoutes } from '../routes/all-routes/authRoutes'; // Import authRoutes object
import Login from '../modules/auth/components/pages/Login'
import PageNotFound from '../modules/common/components/PageNotFound';
import ClinicSignUp from '../modules/auth/components/pages/ClinicSignUp';
import LabSignUp from '../modules/auth/components/pages/LabSignUp';
import ForgetPassword from '../modules/auth/components/pages/ForgetPassword';
import ResetPassword from '../modules/auth/components/pages/ResetPassword';
import UnAuthGuard from '../hocs/layout-guards/UnAuthGuard';


const AuthRouter = () => {
  return (
    <>
    <UnAuthGuard>
      <AuthLayout>
        <Routes>
          <Route path={authRoutes.LOGIN} element={<Login />} />
          <Route path={authRoutes.FORGET_PASSWORD} element={<ForgetPassword />} />
          <Route path={authRoutes.RESET_PASSWORD} element={<ResetPassword />} />
          <Route path={authRoutes.CLINIC_SIGNUP} element={<ClinicSignUp />} />
          <Route path={authRoutes.LAB_SIGNUP} element={<LabSignUp />} />
          <Route path="/*" element={<PageNotFound />} />
        </Routes>
      </AuthLayout>
      </UnAuthGuard>
    </>
  );
};

export default AuthRouter;
