import { useState } from "react";
import { useDispatch } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { login } from "../../../redux/slices/authSlice";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import authPrefixedRoutes from "../../../routes/all-routes/authRoutes";

const useRefreshToken = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize navigate
  const [refreshError, setRefreshError] = useState(null);
  const [refreshSuccess, setRefreshSuccess] = useState(false); // New state for refresh token success
  const [refreshSuccessResponse, setRefreshSuccessResponse] = useState(false); // New state for refresh token success

  const {
    mutate: refreshToken,
    isLoading,
    isError,
  } = useMutation({
    mutationKey: "refresh",
    mutationFn: async (token) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/auth/user/generate-token`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(token),
          }
        );

        const data = await response.json();
        // console.log(data);

        if (!response.ok) {
          const text = await response.json();
          if (text?.error?.status === 401) {
            navigate(authPrefixedRoutes.LOGIN);
          }
          throw new Error(data.error.message);
        }

        // Condition  based on panel type
        if (data.data.panel === "admin") {
          // Dispatch action with the full user data
          dispatch(login(data.data));

          // Set refresh success flag
          setRefreshSuccess(true); // Set submit success flag here

          // Set success response data directly from the response
          setRefreshSuccessResponse(data);
        } else {
          throw new Error("Invalid user type"); // Set custom error message
        }

        return data;
      } catch (error) {
        // Set refresh error state
        setRefreshError(error);
        throw error; // Re-throw the error to be caught by onError
      }
    },
    onError: (error) => {
      setRefreshError(error);
      throw error; // Re-throw the error to be caught by onError
    },
  });

  return {
    refreshToken,
    isLoading,
    isError,
    refreshError,
    refreshSuccess,
    refreshSuccessResponse,
  }; // Return refreshSuccess
};

export default useRefreshToken;
