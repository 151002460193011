import React from "react";
import { HashLoader } from "react-spinners";

const Loader = ({ transparent = false }) => {
  return (
    <div className="w-100 vh-100 d-flex align-items-center justify-content-center">
      <HashLoader color="#36d7b7" size={100} />
    </div>
  );
};

export default Loader;
