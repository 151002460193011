import React from "react";
import Accordion from "react-bootstrap/Accordion";

const Faq = ({ data }) => {
  const { faq_items, title, subtitle } = data?.content;

  return (
    <div className="mw-100 bg-white mt-5">
      <div className="max-width mx-auto py-4 px-4 d-flex flex-column ">
        <div className="row align-items mb-5 text-center">
          <div className="faq-title title-40 col-lg-6 d-flex justify-content-center align-items-center fw-bold ">
            {title}
          </div>
          <div className="col-lg-6 d-flex justify-content-center align-items-center  color-title-color">
            <div className="faq-desc">{subtitle}</div>
          </div>
        </div>
        <Accordion defaultActiveKey="0">
          {faq_items.map((item, index) => (
            <Accordion.Item
              eventKey={index}
              key={index}
              className="faq-accordion"
            >
              <Accordion.Header>{item?.question}</Accordion.Header>
              <Accordion.Body>{item?.answer}</Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default Faq;
