// NetworkError.js
import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

const NetworkError = () => {
  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h1 className="text-center">Network Error</h1>
          <p className="text-center">Please check your internet connection and try again.</p>
          <div className="text-center">
            <Button variant="primary" onClick={() => window.location.reload()}>Reload Page</Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default NetworkError;
