import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import authPrefixedRoutes from "../../routes/all-routes/authRoutes";
import { useNavigate } from "react-router-dom";

const useSingleImageUpload = () => {
  const [imageUrls, setImageUrls] = useState({});
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);

  const navigate = useNavigate();

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async (val) => {
    if (!val) {
      console.error("No file selected.");
      return;
    }
    console.log("what?");
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", val);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/upload/single/image`, // Replace "API_ENDPOINT_HERE" with your API endpoint
        {
          method: "POST",
          body: formData
        }
      );

      if (!response.ok) {
        const text = await response.json();
        if (text?.error?.status === 401) {
          navigate(authPrefixedRoutes.LOGIN);
        }
        throw new Error("Failed to upload file");
      }
      const data = await response.json();

      setImageUrls(data.fileUrl);
      toast.success("Image uploaded");
      return data.fileUrl;
    } catch (error) {
      setError(error.error);
      setIsLoading(false);

      // Show error message
      // May need to correct the error message ****
      toast.error("Failed to upload file");
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const { mutate: uploadImage } = useMutation({
    mutationKey: ["singleImageUpload"],
    mutationFn: async (formData, inputName) => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/upload/single/image`,
          {
            method: "POST",
            body: formData,
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        );

        const data = await response.json();

        if (!response.ok) {
          const text = await response.json();
          if (text?.error?.status === 401) {
            navigate(authPrefixedRoutes.LOGIN);
          }
          throw new Error(data.error);
        }

        setImageUrls((prevUrls) => ({
          ...prevUrls,
          [inputName]: data.fileUrl
        }));

        // Show success message
        toast.success("Image uploaded");
      } catch (error) {
        setError(error.error);
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    onError: (error) => {
      setError(error.error);
      setIsLoading(false);
      // Show error message
      toast.error(error.error);
      throw error;
    }
  });

  return {
    imageUrls,
    error,
    isLoading,
    handleFileChange,
    uploadImage,
    handleUpload,
    file
  };
};

export default useSingleImageUpload;
