// slices/authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Defaults to localStorage for web
import encryptionConfig from "../encryptionConfig";


const persistConfig = {
  key: "auth",
  storage,
  blacklist: [], // Add any state properties that you don't want to persist here
  ...encryptionConfig, // Integrate encryption configuration , this changes the transform:[] 

};

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    user: null
    // Other authentication-related state properties
  },
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      // Reset other authentication-related state properties
    }
    // Other authentication-related reducers
  }
});

const persistedAuthReducer = persistReducer(persistConfig, authSlice.reducer);

export const { login, logout } = authSlice.actions;

export default persistedAuthReducer;
