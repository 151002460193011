import { useState } from "react";
import { Document, Page } from "react-pdf";
import pdf from "../../../../assets/pdf/lorem_ipsum.pdf";
import sample from "../../../../assets/pdf/sample.pdf";
import Loader from "../../../common/components/loader/Loader";
export default function SinglePagePDFViewer() {
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div className="pdf-div">
      {/* <p>
        Page {pageNumber} of {numPages}
      </p> */}
      <Document
        loading={<Loader />}
        file={sample}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <>
          <div className="d-flex justify-content-between align-items-center">
            <p>
              Page {pageNumber} of {numPages}
            </p>
            <div className="mb-3">
              <span
                className=" rad-5 me-3 p-2 bg-primary-color text-black"
                style={{
                  cursor: pageNumber === 1 ? "no-drop" : "pointer"
                }}
                onClick={() => {
                  if (pageNumber !== 1) {
                    setPageNumber(pageNumber - 1);
                  }
                }}
              >
                prev
              </span>
              <span
                className=" rad-5 ms-3 p-2 bg-primary-color text-black"
                style={{
                  cursor: pageNumber === numPages ? "no-drop" : "pointer"
                }}
                onClick={() => {
                  if (pageNumber !== numPages) {
                    setPageNumber(pageNumber + 1);
                  }
                }}
              >
                next
              </span>
            </div>
          </div>
          <Page
            pageNumber={pageNumber}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        </>

        {/* {Array.apply(null, Array(numPages))
          .map((x, i) => i + 1)
          .map((page) => {
            return (
              <>
                <p>
                  Page {pageNumber} of {numPages}
                </p>
                <Page
                  height={900}
                  scale={1}
                  pageNumber={page}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                />
              </>
            );
          })} */}
      </Document>
    </div>
  );
}
