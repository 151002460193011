import React from "react";

const Button = (props) => {
  const { className, label, ...restProps } = props;
  return (
    <button
      {...restProps}
      className={`bg-primary-color fs-16 color-white-cream border-0 py-2 px-4 rad-5 ${className}`}
    >
      {label}
    </button>
  );
};

export default Button;
