import React, { useState } from "react";
import SiteLogoContainer from "./../../../common/components/section/SiteLogoContainer";
import { HamburgerIcon } from "../../../../utils/WebIcons";
import {
  AboutIcon,
  CloseIcon,
  ContactIcon,
  HomeIcon,
} from "../../../../utils/MyIcons";

import Drawer from "react-modern-drawer";
import { webRoutes } from "../../../../routes/all-routes/webRoutes";
import { useLocation, Link, useNavigate } from "react-router-dom";
import "react-modern-drawer/dist/index.css";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleDrawer = () => {
    setIsDrawerOpen((prevState) => !prevState);
  };

  // const toggleDropdown = () => {
  //   setIsOpen(!isOpen);
  // };

  const NavbarMenuItem = ({ label, href, active, icon }) => {
    return (
      <li className="nav-item">
        <a
          className={`nav-item-link ${active ? "active-nav" : "inactive-nav"}`}
          aria-current="page"
          href={href}
        >
          <span className={`me-2 ${active ? "active-nav-svg" : ""} `}>
            {icon}
          </span>
          <span className={`${active ? "active-nav-label" : ""} `}>
            {label}
          </span>
        </a>
      </li>
    );
  };

  const DrawerNavbar = () => {
    return (
      <>
        <div onClick={toggleDrawer} className="">
          <HamburgerIcon />
        </div>
        <Drawer
          open={isDrawerOpen}
          onClose={toggleDrawer}
          size={300}
          direction="right"
          duration={3000}
          className="bla bla bla"
        >
          <div className="mh-100 d-flex justify-content-center flex-column align-items-start accordion-button ms-3">
            <Navbar mobile="true" />
            <LoginButtonContainer />
          </div>
        </Drawer>
      </>
    );
  };

  const LoginButtonContainer = () => {
    return (
      <div className="dropdown">
        <a
          href={`${process.env.REACT_APP_LAB_CLINIC_PANEL_URL}`}
          target="_blank"
          rel="noreferrer"
          className="btn bg-primary-color fs-16 py-2 px-5 color-white-cream dropdown-toggles "
          type="button"
          // onClick={toggleDropdown}
          aria-expanded={isOpen ? "true" : "false"}
        >
          Login
        </a>
      </div>
    );
  };

  const Navbar = ({ mobile = false }) => {
    return (
      <nav
        className={`navbar navbar-light d-flex ${
          mobile
            ? "align-items-start  "
            : "align-items-center justify-content-center"
        }`}
      >
        <ul
          className={`navbar-nav  mb-2 mb-lg-0 ${
            mobile ? "flex-column " : "flex-row me-auto"
          }`}
        >
          <NavbarMenuItem
            href={webRoutes.HOME}
            active={location.pathname === "/"}
            label={"Home"}
            // icon={<HomeIcon />}
          />

          <NavbarMenuItem
            href={webRoutes.ABOUT_US}
            active={location.pathname.includes("about-us")}
            label={"About Us"}
            // icon={<AboutIcon />}
          />

          <NavbarMenuItem
            href={webRoutes.CONTACT_US}
            active={location.pathname.includes("contact-us")}
            label={"Contact Us"}
            // icon={<ContactIcon />}
          />
        </ul>
        {mobile ? (
          <span className=" cursor-pointer  " onClick={toggleDrawer}>
            <CloseIcon />
          </span>
        ) : null}
      </nav>
    );
  };

  return (
    <div className="mw-100 bg-white-cream ">
      <div className="max-width mx-auto py-4 px-4 d-flex justify-content-between align-items-center ">
        <div
          className="logo-container cursor-pointer"
          onClick={() => navigate("/")}
        >
          <SiteLogoContainer />
        </div>
        <div className="nav-bar-container d-none d-lg-block">
          <Navbar />
        </div>

        <div className="d-none d-lg-block">
          <LoginButtonContainer />
        </div>

        <div className="d-lg-none">
          <DrawerNavbar />
        </div>
      </div>
    </div>
  );
};

export default Header;
