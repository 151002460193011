// useWebContent.js
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { commonRoutes } from "../../../../../routes/all-routes/commonRoutes";
import { useSelector } from "react-redux";
import authPrefixedRoutes from "../../../../../routes/all-routes/authRoutes";

const useGetServiceCountSection = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.user.accessToken);
  const [serviceCountData, setServiceCountData] = useState();
  const [placeHolder, setPlaceHolder] = useState();

  const { isLoading, isError, data } = useQuery({
    queryKey: ["getserviceCountSection"],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/admin/website-content/home-page/service-count-section`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (!response.ok) {
        const text = await response.json();
        if (text?.error?.status === 401) {
          navigate(authPrefixedRoutes.LOGIN);
        }
        navigate(commonRoutes.SERVER_ERROR); // Navigate to error500 page
        return;
      }
      const data = await response.json();
      setServiceCountData(data?.data?.content);
      setPlaceHolder(data?.data?.placeholder);

      return data;
    }
  });

  const handleRemovePair = (dataIndex, name, count) => {
    setServiceCountData(
      serviceCountData?.filter(
        (item, index) =>
          index !== dataIndex && item?.count !== count && item?.name !== name
      )
    );
  };

  return {
    isLoading1: isLoading,
    isError1: isError,
    data1: data,
    placeHolder,
    handleRemovePair,
    serviceCountData
  };
};

export default useGetServiceCountSection;
