import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { authPrefixedRoutes } from "../../../routes/all-routes/authRoutes";

const useGetCountryCode = () => {
  const [sortedCountryCode, setSortedCountryCode] = useState([]);
  const navigate = useNavigate(); // React Router v6's navigate function
  const [isCountryCodeLoading, setIsCountryCodeLoading] = useState(false);
  const [countryCodeError, setCountryCodeError] = useState(null);
  const [countryCodeData1, setCountryCodeData1] = useState([]);

  const fetchCountryCode = useQuery({
    queryKey: ["countryCode"],
    queryFn: async () => {
      setIsCountryCodeLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/public/all-country-code`,
        {
          method: "GET",
          headers: {},
        }
      );
      if (!response.ok) {
        const text = await response.json();
        if (text?.error?.status === 401) {
          navigate(authPrefixedRoutes.LOGIN);
        }
        const text1 = await response.text(); // Get response text for error handling
        console.error("Error response:", text);
        setCountryCodeError(text1);
        setIsCountryCodeLoading(false);
        throw new Error(text1); // Throw an error to trigger onError callback
      }
      const data = await response.json();
      setCountryCodeData1(data?.data);
      setSortedCountryCode(data?.data?.sort());
      setIsCountryCodeLoading(false);
      return data; // Return the fetched data
    },
  });

  useEffect(() => {
    fetchCountryCode.refetch();
  }, []);

  return {
    isCountryCodeLoading: isCountryCodeLoading,
    isCountryCodeError: countryCodeError,
    countryCodeData: countryCodeData1,
    sortedCountryCode,
  };
};

export default useGetCountryCode;
