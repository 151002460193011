import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import useUpdateFaqSection from "../../../../hooks/data-hooks/admin/homepage/faq-section/useUpdateFaqSection"; // Importing custom hook for API call
import useGetFaqSection from "../../../../hooks/data-hooks/admin/homepage/faq-section/useGetFaqSection";
import Button from "../../../web/components/button/Button";
import Loader from "../../../common/components/loader/Loader";

const FaqSection = () => {
  const {
    faqContent,
    isLoading1,
    isError1,
    handleRemovePair,
    handleUpdateFaqContent,
    data,
  } = useGetFaqSection();

  const [inputPairs, setInputPairs] = useState(0);

  useEffect(() => {
    handleUpdateFaqContent();
  }, [data]);

  useEffect(() => {
    // Set initial inputPairs based on the length of faq_items array
    if (faqContent) {
      setInputPairs(faqContent?.faq_items.length);
    }
  }, [faqContent]);

  const handleAddPair = () => {
    setInputPairs((prevCount) => prevCount + 1);
  };

  const {
    register,
    handleSubmit,
    formState: { state, errors, isSubmitting },
    setValue,
  } = useForm();

  useEffect(() => {
    // Set default values if faqContent is available
    if (faqContent) {
      setValue("title", faqContent?.title);
      setValue("subtitle", faqContent?.subtitle);
      faqContent?.faq_items.forEach((item, index) => {
        setValue(`faq_items[${index}].question`, item.question);
        setValue(`faq_items[${index}].answer`, item.answer);
      });
    }
  }, [faqContent]);

  const { updateContent, isLoading, isError, error, submitSuccess } =
    useUpdateFaqSection();

  useEffect(() => {
    // Show error toast if isError is true
    if (isError) {
      toast.error(error.message);
    }
  }, [isError, error]);

  // useEffect(() => {
  //   // Show success toast if submitSuccess is true
  //   if (submitSuccess) {
  //     toast.success("Form Submit Successful");
  //   }
  // }, [submitSuccess]);

  const onSubmit = async (data) => {
    // console.log(data);
    const formattedData = {
      ...data,
      faq_items: data.faq_items.filter(
        (item) => item?.question !== "" || item?.answer !== ""
      ),
    };

    // Prevent form submission if there is an ongoing login process

    if (isSubmitting) return;

    try {
      // Call the custom hook for API call
      await updateContent(formattedData);
    } catch (error) {
      // Error handling is done in useEffect
    }
  };

  if (isLoading1) return <Loader />;
  return (
    <div className="cms-section">
      <div className="cms-card  ">
        <form className="cms-form mb-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="fs-18px fw-bold mb-3">FAQ Section</div>
          <div className="cms-content custom-scroll">
            <div className="form-item">
              <label>Title</label>
              <input
                type="text"
                placeholder="Enter title here"
                {...register("title", {
                  required: "Field is required",
                })}
              />
            </div>
            <div className="form-item">
              <label>Subtitle</label>
              <input
                type="text"
                placeholder="Enter subtitle here"
                {...register("subtitle", {
                  required: "Field is required",
                })}
              />
            </div>
            {Array.from({ length: inputPairs }).map((_, index) => (
              <div key={index}>
                <div className="form-item">
                  <div className="d-flex justify-content-between">
                    <label>Question</label>
                    <button
                      type="button"
                      className="border-0 px-2"
                      onClick={() => {
                        handleRemovePair(
                          index,
                          faqContent?.faq_items[index]?.question
                        );
                        setInputPairs((prevCount) => prevCount - 1);
                        setValue(`faq_items[${index}].question`, "");
                        setValue(`faq_items[${index}].answer`, "");
                      }}
                    >
                      X
                    </button>
                  </div>
                  <input
                    rows={5}
                    placeholder="Enter your question here"
                    {...register(`faq_items[${index}].question`, {
                      required: "Field is required",
                    })}
                  />
                </div>
                <div className="form-item">
                  <label>Answer</label>
                  <textarea
                    rows={5}
                    placeholder="Enter your answer here"
                    {...register(`faq_items[${index}].answer`, {
                      required: "Field is required",
                    })}
                  />
                </div>
                <hr />
              </div>
            ))}

            {/* Add More button */}
            <button
              type="button"
              className="w-25 button-primary p-2 "
              onClick={handleAddPair}
            >
              Add More
            </button>
          </div>

          <Button
            label={isLoading ? "Submitting" : "Submit"}
            type="submit"
            className={`w-100  ${isLoading ? "bg-disabled" : ""}`}
            disabled={isLoading}
          />
        </form>
      </div>
    </div>
  );
};
export default FaqSection;
