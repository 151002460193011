import { statsData } from "../../../../dummy-data/admin/pages/dashboard/dummy-data";
import StatsCard from "./StatsCard";
const Stats = () => {
  return (
    <div className="d-flex flex-wrap  bg-white rounded-4 border h-100  ">
      {statsData.map((data) => {
        return <StatsCard key={data.id} data={data} />;
      })}
    </div>
  );
};
export default Stats;
