// useGetProductCategoryList.js
import {  useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { authPrefixedRoutes } from "../../../../routes/all-routes/authRoutes";

const useOrder = (orderId, initialQueryParams) => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.user.accessToken);
  const [orderList, setOrderList] = useState();
  const [orderListMeta, setOrderListMeta] = useState();
  const [orderListError, setOrderListError] = useState(null);
  const [chatListError, setChatListError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [chatList, setChatList] = useState();
  const [isChatLoading, setChatLoading] = useState(false);

  function sortArrayByLastMessage(array) {
    return array.sort((a, b) => {
      return new Date(b.lastMessageTime) - new Date(a.lastMessageTime);
    });
  }

  const fetchOrderList = useQuery({
    queryKey: ["orderlist", initialQueryParams],
    queryFn: async () => {
      try {
        setIsLoading(true);
        // const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/admin/order-management/orders`;
        let url = constructOrderListUrl(initialQueryParams);

        // const response = await fetch(apiUrl, {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          const text = await response.json();
          if (text?.error?.status === 401) {
            navigate(authPrefixedRoutes.LOGIN);
          }
          const text1 = await response.text();
          setOrderListError(text1);
          throw new Error("Failed to fetch order details");
        }

        const responseData = await response.json();
        // setOrderList(responseData?.data);
        setOrderList(sortArrayByLastMessage(responseData?.data)); // Assuming responseData contains order details
        setOrderListMeta(responseData?.meta);
        setIsLoading(false);

        // return responseData?.data;
        return responseData;
      } catch (error) {
        setOrderListError(error);
        setIsLoading(false);
        throw error;
      }
      // finally {
      //   setIsLoading(false);
      // }
    },
    onError: (error) => {
      setOrderListError(error);
      setIsLoading(false);
      throw error;
    },
  });

  const fetchOrder = () => {
    fetchOrderList.refetch();
  }

  const fetchOrderListWithParams = (queryParams) => {
    //console.log("qp:",queryParams);
    const apiUrl = constructOrderListUrl(queryParams);
    //console.log("apiUrl:",apiUrl);
    fetchOrderList.refetch(apiUrl);
  };

  // const fetchOrderList = async () => {
  //   try {
  //     setIsLoading(true);
  //     const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/admin/order-management/orders`;
  //     const response = await fetch(apiUrl, {
  //       method: "GET",
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json",
  //       },
  //     });

  //     if (!response.ok) {
  //       const text = await response.json();
  //       if (text?.error?.status === 401) {
  //         navigate(authPrefixedRoutes.LOGIN);
  //       }
  //       const text1 = await response.text();
  //       setOrderListError(text1);
  //       throw new Error("Failed to fetch clinic details");
  //     }

  //     const responseData = await response.json();
  //     setOrderList(sortArrayByLastMessage(responseData?.data) || []); // Assuming responseData contains clinic details
  //     // console.log(responseData);
  //     setOrderListMeta(responseData?.meta);
  //     return responseData?.data;
  //   } catch (error) {
  //     setOrderListError(error);

  //     throw error;
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const fetchChatMessage = async () => {
    try {
      setChatLoading(true);
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/message/admin/chat/${orderId}`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        const text = await response.json();
        if (text?.error?.status === 401) {
          navigate(authPrefixedRoutes.LOGIN);
        }
        if (text?.error) {
          setChatList([]);
          return;
        }
        console.log(text);
        // setOrderListError(text);
        throw new Error("Failed to fetch clinic details");
      }

      const responseData = await response.json();

      setChatList(responseData?.data); // Assuming responseData contains clinic details
      return responseData?.data;
    } catch (error) {
      setChatListError(error);
      throw error;
    } finally {
      setChatLoading(false);
    }
  };

  const constructOrderListUrl = (queryParams) => {
    const queryString = Object.keys(queryParams)
      .map((key) => `${key}=${queryParams[key]}`)
      .join("&");
    return `${process.env.REACT_APP_API_BASE_URL}/api/admin/order-management/orders?${queryString}`;
  };

  return {
    orderListMeta,
    orderList,
    isLoading,
    orderListError,
    chatListError,
    chatList,
    isChatLoading,
    fetchChatMessage,
    fetchOrderList,
    fetchOrder,
    fetchOrderListWithParams,
  };
};

export default useOrder;
