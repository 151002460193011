import React from "react";

const HeroSection2 = ({ data }) => {
  return (
    <div className="max-width mx-auto py-4 px-4">
      <div className="container mt-5">
        <div className="row justify-content-center">
          {data?.map((item) => (
            <div
              key={JSON.stringify(item)}
              className={`hero-section-2 rad-10 d-flex justify-content-between align-items-center col-lg-5  position-relative mb-4 me-4 `}
              style={{
                backgroundImage: `url(${item?.background_image})`, // Assuming the API provides a 'backgroundImage' field
                backgroundSize: "cover",
                backgroundPosition: "center"
              }}
            >
              <div className=" font-family-secondary fw-semibold fs-24 color-white-cream">
                {item?.title}
              </div>

              <img
                src={item?.image}
                alt="Overlay Image"
                className=" img-fluid"
                style={{ maxWidth: "50%", zIndex: 1 }} // Adjust size and z-index as needed
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HeroSection2;
