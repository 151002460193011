// AdminRouter.js
import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";

// Import Admin panel components
import AdminLayout from "../modules/admin/components/layout/AdminLayout";
import AdminDashboard from "../modules/admin/components/pages/Dashboard";
import AdminClinic from "../modules/admin/components/pages/AdminClinic";
import AdminClinicDetails from "../modules/admin/components/pages/AdminClinicDetails";
import AdminLab from "../modules/admin/components/pages/AdminLab";
import AdminLabDetails from "../modules/admin/components/pages/AdminLabDetails";
import PageNotFound from "../modules/common/components/PageNotFound.js";
import { adminRoutes } from "./../routes/all-routes/adminRoutes";
import Loader from "../modules/common/components/loader/Loader.js";
//import Settings from "../modules/admin/components/pages/Settings.js";
import AdminAuthGuard from "../hocs/layout-guards/AdminAuthGuard.js";
import Orders from "../modules/admin/components/pages/Orders.js";
import ProductList from "../modules/admin/components/pages/ProductList.js";
import ProductCategory from "../modules/admin/components/pages/ProductCategory.js";
import ProductDetails from "../modules/admin/components/pages/ProductDetails.js";
import OrderDetails from "../modules/admin/components/pages/OrderDetails.js";
import CreateProductCategory from "../modules/admin/components/pages/CreateProductCategory.js";
import UpdateProductCategory from "../modules/admin/components/pages/UpdateProductCategory.js";
import Message from "../modules/admin/components/message/Message.js";
import WebContentSettings from "../modules/admin/components/pages/WebContentSettings.js";
import GeneralSettings from "../modules/admin/components/pages/GeneralSettings.js";
import Transaction from "../modules/admin/components/pages/Transaction.js";
import TransactionDetails from "../modules/admin/components/pages/TransactionDetails.js";

const AdminRouter = () => {
  return (
    <AdminAuthGuard>
      <AdminLayout>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path={adminRoutes.DASHBOARD} element={<AdminDashboard />} />
            <Route path={adminRoutes.USERS_CLINIC} element={<AdminClinic />} />
            <Route
              path={`${adminRoutes.USERS_CLINIC_DETAILS}/:id/:name`}
              element={<AdminClinicDetails />}
            />
            <Route path={adminRoutes.USERS_LAB} element={<AdminLab />} />
            <Route
              path={`${adminRoutes.USERS_LAB_DETAILS}/:id/:name`}
              element={<AdminLabDetails />}
            />
            <Route path={adminRoutes.PRODUCT_LIST} element={<ProductList />} />
            <Route
              path={`${adminRoutes.PRODUCT_LIST}/:id`}
              element={<ProductDetails />}
            />
            <Route
              path={adminRoutes.PRODUCT_CATEGORY}
              element={<ProductCategory />}
            />
            <Route
              path={adminRoutes.CREATE_PRODUCT_CATEGORY}
              element={<CreateProductCategory />}
            />
            <Route
              path={`${adminRoutes.PRODUCT_CATEGORY}/:id`}
              element={<UpdateProductCategory />}
            />
            <Route path={adminRoutes.ORDERS} element={<Orders />} />
            <Route
              path={`${adminRoutes.ORDERS}/:id`}
              element={<OrderDetails />}
            />
            <Route path={adminRoutes.TRANSACTION} element={<Transaction />} />
            <Route
              path={`${adminRoutes.TRANSACTION}/:id`}
              element={<TransactionDetails />}
            />

            <Route path={adminRoutes.MESSAGE} element={<Message />} />
            <Route path={`${adminRoutes.MESSAGE}/:id`} element={<Message />} />
            {/* <Route path={adminRoutes.SETTINGS} element={<Settings />} /> */}
            <Route
              path={adminRoutes.WEB_CONTENT_SETTINGS}
              element={<WebContentSettings />}
            />
            <Route
              path={adminRoutes.GENERAL_SETTINGS}
              element={<GeneralSettings />}
            />
            {/* import report */}

            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Suspense>
      </AdminLayout>
    </AdminAuthGuard>
  );
};

export default AdminRouter;
