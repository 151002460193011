import React, { Suspense } from "react";
import "../../../../styles/outerPanel.css";
import "../../../../styles/weblayout.css";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import useWebContent from "../../../../hooks/data-hooks/website/useWebContent";
import { useSelector } from "react-redux";
import Loader from "../../../common/components/loader/Loader";

const WebLayout = ({ children }) => {
  const { isLoading, isError, data, webContent } = useWebContent();
  const webContentState = useSelector((state) => {
    return state.webContent;
  }); // Get webContent state from Redux store

  return (
    <Suspense fallback={<Loader />}>
      {isLoading && <Loader />}
      {!isLoading && !isError && (
        <div className="body-wrapper d-flex h-100 flex-column align-items-center justify-content-between">
          <>
            <div className="w-100">
              <Header />
              <div className="content-wrapper">{children}</div>
              <Footer />
            </div>
          </>
        </div>
      )}
    </Suspense>
  );
};

export default WebLayout;
