import React, { useEffect, useMemo } from "react";
import { Rating } from "react-simple-star-rating";

// import ReactPaginate from "react-paginate";
// import { clinicTotalOrderData } from "../../../../dummy-data/admin/pages/user/Clinic/clinicTotalOrderData";
import { useState } from "react";
import { StarIcon } from "../../../../utils/MyIcons";
// import Table2 from "../table/Table2";
// import Map from "../map/Map";
import useClinicDetails from "../../../../hooks/data-hooks/admin/clinic-management/useClinicDetails";
import { useParams } from "react-router-dom";
import MyImage from "../../../../custom-components/MyImage";
import { Str } from "@jimuelpalaca/str";
import ProfileBarShimmer from "../../../common/components/shimmers/ProfileBarShimmer";
import DataTable from "react-data-table-component";
// import Loader from "../../../common/components/loader/Loader";

const AdminClinicDetails = () => {
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  // Function to handle page change
  // const handlePageClick = ({ selected }) => {
  //   setCurrentPage(selected);
  // };

  // -- real Logic

  let { id, name } = useParams();

  const {
    fetchClinicDetailsWithId,
    isLoading,
    isError,
    clinicDetails,
    clinicDetailsError,
    orderList,
    fetchProductsOfClinic
  } = useClinicDetails(id, name);


  useEffect(() => {
    fetchClinicDetailsWithId(id); // Fetch clinic details when clinicId changes
  }, [id]);

  const tableHeaderstyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "#ccc"
      }
    }
  };

  const handlePageChange = (page) => {
    fetchProductsOfClinic(perPage, page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchProductsOfClinic(newPerPage, page);
    setCurrentPage(page);
    setPerPage(newPerPage);
  };

  const columnsOfOrderList = useMemo(
    () => [
      {
        name: "#",
        selector: (row, index) => index + 1,
        sortable: true
      },
      {
        name: "Order Id",
        selector: (row) => row.orderId,
        sortable: true
      },
      {
        name: "Ordered By",
        selector: (row) => row.userId.email,
        sortable: true
      },

      {
        name: "Order Status",
        selector: (row) => row.orderStatus,
        sortable: true
      },
      {
        name: "Payment Status",
        selector: (row) => row.paymentStatus,
        sortable: true
      },
      {
        name: "Delivery Status",
        selector: (row) => row.deliveryStatus,
        sortable: true
      }
    ],
    []
  );

  return (
    <div className="container ">
      <h5>
        Clinic Profile :&nbsp;
        {!isLoading && clinicDetails ? clinicDetails.clinicName : "Loading..."}
      </h5>

      <div className="d-flex flex-column flex-lg-row gap-3 ">
        {/* Profile */}
        <div className="bg-white rounded-3 p-4 w-lg-25 ">
          {(isLoading || isError) && <ProfileBarShimmer />}
          {!isLoading && !isError && clinicDetails && (
            <>
              <div className="text-center">
                <MyImage
                  src={clinicDetails.clinicImageUrl}
                  addBaseUrl={true}
                  alt=""
                  className="w-h-130px rounded-circle border border-5  border-primary-color my-3"
                />
                <div className="mt-2 vstack gap- ">
                  <span className="fw-bold text-title ">
                    {clinicDetails?.clinicName ?? "Name unavailable"}
                  </span>
                  <span className="fs-14 ">
                    {clinicDetails?.clinicPhoneNumber ??
                      "Phone number  unavailable"}
                  </span>
                  <span className="fs-14 ">
                    Status:
                    {Str.snakeToWord(clinicDetails?.status ?? "unknown")}
                  </span>
                </div>
              </div>
              <hr />
              <div className="d-flex fs-12 ">
                <div className="d-flex flex-column w-50 gap-2 ">
                  <span>Followers</span>
                  <span>Clinic Ratings</span>
                  <span>Total Feedback</span>
                </div>
                <div className="d-flex flex-column gap-2">
                  <span>: 2.5 K</span>
                  <span>
                    : <Rating initialValue={2} readonly size={15} />
                    &nbsp;(2 (dummy))
                  </span>
                  <span>: 2167</span>
                </div>
              </div>
              <hr />
              <p className="fs-12">
                Your trusted destination for comprehensive healthcare, where
                wellness meets expertise for optimal health outcomes. Experience
                personalized care at <b>Core Well Clinic</b> , where our
                dedicated team combines advanced medical technology.
              </p>
              <hr />
              <p className="fs-12">
                121 South street, Dream holiday park, California <br />
                email: corewellclinic@email.com <br />
                Website: corewellclinic.com
              </p>
              <img
                src="/dummy-images/x-ray.jpg"
                alt=""
                className="w-100 rounded-3 "
              />
            </>
          )}
        </div>
        {/* Table */}
        <div className="flex-grow-1 ">
          <h5 className="bg-primary-color p-4 text-white rounded-top-3 mb-0 ">
            Total Orders information
          </h5>

          <DataTable
            // title={headerComponent}
            customStyles={tableHeaderstyle}
            columns={columnsOfOrderList}
            data={orderList}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            fixedHeader
            selectableRowsHighlight
            highlightOnHover
          />
        </div>
      </div>
    </div>
  );
};
export default AdminClinicDetails;
