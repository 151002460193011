// ClinicRouter.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Import Clinic panel components
import ClinicLayout from '../modules/clinic/components/layout/ClinicLayout';
import ClinicDashboard from '../modules/clinic/components/pages/Dashboard';
import ClinicAppointments from '../modules/clinic/components/pages/Appointments';

const ClinicRouter = () => {
  return (
    <ClinicLayout>
      <Routes>
        <Route path="/" element={<ClinicDashboard />} />
        <Route path="/appointments" element={<ClinicAppointments />} />
      </Routes>
    </ClinicLayout>
  );
};

export default ClinicRouter;
