import { useState } from 'react';
import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import authPrefixedRoutes from '../../../../routes/all-routes/authRoutes';


const useActiveDeactiveClinic = () => {
    const token = useSelector((state) => state.auth.user.accessToken);

    const [activeDeactiveClinicLoading, setActiveDeactiveClinicLoading] = useState(false);
    const [activeDeactiveClinicError, setActiveDeactiveClinicError] = useState(null);
    const [activeDeactiveClinicSuccess, setActiveDeactiveClinicSuccess] = useState(false);
    const navigate = useNavigate();

    const { mutate: activeDeactiveClinic, isLoading, isError } = useMutation({
        mutationKey: "activeDeactiveClinic",
        mutationFn: async (clinicId) => {
            try {
                setActiveDeactiveClinicLoading(true);
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/admin/clinic-management/activate-or-deactivate-clinic/${clinicId}`, {
                    method: "PUT",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    },
                    body: JSON.stringify({}),
                });

                const data = await response.json();

                if (!response.ok) {
                    const text = await response.json();
                    if (text?.error?.status === 401) {
                      navigate(authPrefixedRoutes.LOGIN);
                    }
                    throw new Error(data.message);
                }

                setActiveDeactiveClinicSuccess(true);
                setActiveDeactiveClinicLoading(false);
                return data;
            } catch (error) {
                setActiveDeactiveClinicError(error);
                setActiveDeactiveClinicLoading(false);
                throw error;
            }
        },
        onError: (error) => {
            setActiveDeactiveClinicError(error);
            setActiveDeactiveClinicLoading(false);
            throw error;
        },
    });

    return { activeDeactiveClinic, activeDeactiveClinicLoading, activeDeactiveClinicError, activeDeactiveClinicSuccess };
};

export default useActiveDeactiveClinic;
