// App.js
import React from "react";
import MainRouter from "./routes/MainRouter";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { store } from "./redux/store";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      staleTime: 1000 * 60 * 60, // 1 hour in ms
      cacheTime: 0, // 1 hour in ms
      refetchOnWindowFocus: false, // default: true
    },
  },
});

function App() {
  return (
    <HelmetProvider> 
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <div className="App">
            <MainRouter />
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
              // transition:Bounce
            />
          </div>
        </QueryClientProvider>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
