import {
  data,
  COLORS
} from "../../../../dummy-data/admin/pages/dashboard/pieChartDummyData";
import { PieChart, Pie,  Cell } from "recharts";


const DashboardPieChart = () => {
  return (
    <div className="text-center mx-auto bg-white rounded-4 border p-3   h-100  d-flex flex-column justify-content-center align-items-center   ">
      {/* vstack gap-2 */}
      <span className="text-title">Monthly Profits</span>
      <PieChart width={300} height={200}>
        <Pie
          data={data}
          cx={150}
          cy={100}
          innerRadius={45}
          outerRadius={80}
          fill="#8884d8"
          paddingAngle={3}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
      <div className="d-flex align-items-center gap-4 justify-content-between px-2    ">
        {/* Render colored dots and labels */}
        {data.map((entry, index) => (
          <div key={`legend-${index}`} className="d-flex align-items-center">
            <span
              className="dot"
              style={{ backgroundColor: COLORS[index % COLORS.length] }}
            ></span>
            <span className="fs-14">{entry.name}</span>
          </div>
        ))}
      </div>
      
    </div>
  );
};
export default DashboardPieChart;
