// webContentSlice.js
import { createSlice } from "@reduxjs/toolkit";

export const webContentSlice = createSlice({
  name: "webContent",
  initialState: {
    data: null,
    loading: false,
    error: null
  },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    }
  }
});

export const { setData, setLoading, setError } = webContentSlice.actions;

export default webContentSlice.reducer;
