import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from "recharts";
import { data } from "../../../../dummy-data/admin/pages/dashboard/areaChartDummyData";

const DashboardAreaChart = () => {
  return (
    <div className="bg-white border rounded-4 h-100 p-3 overflow-auto d-flex justify-content-center  ">
      <AreaChart
        width={800}
        height={300}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="#8884d8" />
      </AreaChart>
    </div>
  );
};
export default DashboardAreaChart;
