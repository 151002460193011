import { useState } from "react";
import { useDispatch } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { login } from "../../../redux/slices/authSlice";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import { adminPrefixedRoutes } from "../../../routes/all-routes/adminRoutes";
import authPrefixedRoutes from "../../../routes/all-routes/authRoutes";

const useLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize navigate
  const [loginError, setLoginError] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(false); // New state for submit success
  const [loginSuccessResponse, setLoginSuccessResponse] = useState(false); // New state for submit success

  const {
    mutate: loginUser,
    isLoading,
    isError,
  } = useMutation({
    mutationKey: "login",
    mutationFn: async (formData) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/auth/signin`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        const data = await response.json();
        // console.log(data);

        if (!response.ok) {
          const text = await response.json();
          if (text?.error?.status === 401) {
            navigate(authPrefixedRoutes.LOGIN);
          }
          throw new Error(data.error.message);
        }

        // Conditional login based on panel type
        if (data.data.panel === "admin") {
          // Dispatch login action with the full user data
          dispatch(login(data.data));

          // Set login success flag
          setSubmitSuccess(true); // Set submit success flag here

          // Set success response data directly from the response
          setLoginSuccessResponse(data);

          // Delay navigation for 1 second
          setTimeout(() => {
            // Redirect user to dashboard or any other desired route
            navigate(adminPrefixedRoutes.DASHBOARD); 
          }, 1500);
          
          
        } else {
          throw new Error("Only admin can log in."); // Set custom error message
        }

        return data;
      } catch (error) {
        // Set login error state
        setLoginError(error);
        throw error; // Re-throw the error to be caught by onError
      }
    },
    onError: (error) => {
      setLoginError(error);
      throw error; // Re-throw the error to be caught by onError
    },
  });

  return { loginUser, isLoading, isError, loginError, submitSuccess,loginSuccessResponse }; // Return submitSuccess
};

export default useLogin;
