// rootReducer.js
import { combineReducers } from "@reduxjs/toolkit";
import persistedForgetPasswordReducer from "./slices/forgetPasswordSlice";
import webContentReducer from "./slices/webContentSlice";
import persistedAuthReducer from "./slices/authSlice";
import persistedSignUpReducer from "./slices/signUpSlice";

const rootReducer = combineReducers({
  forgetPassword: persistedForgetPasswordReducer,
  webContent: webContentReducer,
  auth: persistedAuthReducer, // Use the persisted version of the auth reducer
  signUp: persistedSignUpReducer // Use the persisted version of the signUP reducer
  // Add other reducers as needed
});

export default rootReducer;
