import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/slices/authSlice";
import { useNavigate } from "react-router-dom";
import { authPrefixedRoutes } from "../../routes/all-routes/authRoutes";
import { toast } from "react-toastify";

const useLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = useCallback(() => {


    // Show a toast message indicating logout
    toast.info("Logging out...");

    // Redirect to login route after 1500ms
    setTimeout(() => {
    // Dispatch logout action
    //dispatch(logout());
      navigate(authPrefixedRoutes.LOGIN);
    }, 1500);
  }, [dispatch, navigate]);

  return handleLogout;
};

export default useLogout;
