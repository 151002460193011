import { useState } from 'react';
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from 'react-router-dom';
import { authPrefixedRoutes } from '../../../routes/all-routes/authRoutes';

const useResendSignUp = () => {
    const navigate = useNavigate();
    const [resendError, setResendError] = useState(null);
    const [resendSubmitSuccess, setResendSubmitSuccess] = useState(false);
    const [resendSuccessResponse, setResendSuccessResponse] = useState(null);

    const { mutate: resendSignUpLab, isLoading: isResendLoading, isError: isResendError } = useMutation({
        mutationKey: "resendSignUpLab",
        mutationFn: async (formData) => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/auth/resend-otp/signup-verification`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(formData),
                });

                const data = await response.json();

                if (!response.ok) {
                    const text = await response.json();
                    if (text?.error?.status === 401) {
                      navigate(authPrefixedRoutes.LOGIN);
                    }
                    setResendError(data.error);
                    throw new Error(data.error.message);
                }

                setResendSubmitSuccess(true);
                setResendSuccessResponse(data);

                // Delay navigation for 1 second
                setTimeout(() => {
                    navigate(authPrefixedRoutes.VERIFY_SIGNUP_OTP);
                }, 1500);

                return data;
            } catch (error) {
                setResendError(error);
                throw error;
            }
        },
        onError: (error) => {
            setResendError(error);
            throw error;
        },
    });

    return { resendSignUpLab, isResendLoading, isResendError, resendError, resendSubmitSuccess, resendSuccessResponse };
};

export default useResendSignUp;
