// Home.js
import React from "react";
import { Helmet } from "react-helmet-async";
import HeroSection from "../hero-section/HeroSection";
import Faq from "../faq/Faq";
import Testimonial from "../testimonial/Testimonial";
import HealthClinics from "../health-clinics/HealthClinics";
import HeroSection1 from "../hero-section-1/HeroSection1";

import heroSectionImg from "./../../../../assets/image/hero-section1.jpg";
import heroSectionImg2a from "./../../../../assets/image/hero-section-2-a.jpg";
import heroSectionImg2b from "./../../../../assets/image/hero-section-2-b.jpg";
import HeroSection2 from "../hero-section-2/HeroSection2";
import MemberStats from "../member-stats/MemberStats";
import Partner from "../partner/Partner";
import Loader from "../../../common/components/loader/Loader";
import useWebContent from "../../../../hooks/data-hooks/website/useWebContent";
import { authPrefixedRoutes } from "../../../../routes/all-routes/authRoutes";

const Home = () => {
  const { isLoading, data } = useWebContent();

  if (isLoading) return <Loader />;
  return (
    <div className="w-100 bg-white">
      <Helmet>
        <title>Home | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <HeroSection data={data?.data?.hero} />
      {/* Clinic Register Content Area */}
      <HeroSection1
        data={data?.data?.clinic_content}
        img1={heroSectionImg}
        buttonUrl={authPrefixedRoutes.CLINIC_SIGNUP}
      />
      {/* Lab Register Content Area */}
      <HeroSection1
        order={2}
        data={data?.data?.lab_content}
        img1={heroSectionImg2a}
        img2={heroSectionImg2b}
        buttonUrl={authPrefixedRoutes.LAB_SIGNUP}
      />
      <HeroSection2 data={data?.data?.service?.content} />
      <MemberStats
        data={data?.data?.service_count}
        serviceCard={data?.data?.service_card}
      />
      <HealthClinics data={data?.data?.content} />
      <Testimonial data={data?.data?.testimonial} />
      <Faq data={data?.data?.faq} />
      <Partner data={data?.data?.brand?.content} />
    </div>
  );
};

export default Home;
