import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import authPrefixedRoutes from "../../../../routes/all-routes/authRoutes";
import { useNavigate } from "react-router-dom";

const useActiveDeactiveLab = () => {
  const token = useSelector((state) => state.auth.user.accessToken);

  const [activeDeactiveLabLoading, setActiveDeactiveLabLoading] =
    useState(false);
  const [activeDeactiveLabError, setActiveDeactiveLabError] = useState(null);
  const [activeDeactiveLabSuccess, setActiveDeactiveLabSuccess] =
    useState(false);

  const navigate = useNavigate();

  const {
    mutate: activeDeactiveLab,
    isLoading,
    isError,
  } = useMutation({
    mutationKey: "activeDeactiveLab",
    mutationFn: async (labId) => {
      try {
        setActiveDeactiveLabLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/admin/lab-management/activate-or-deactivate-lab/${labId}`,
          {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({}),
          }
        );

        const data = await response.json();

        if (!response.ok) {
          const text = await response.json();
          if (text?.error?.status === 401) {
            navigate(authPrefixedRoutes.LOGIN);
          }
          throw new Error(data.message);
        }

        setActiveDeactiveLabSuccess(true);
        setActiveDeactiveLabLoading(false);
        return data;
      } catch (error) {
        setActiveDeactiveLabError(error);
        setActiveDeactiveLabLoading(false);
        throw error;
      }
    },
    onError: (error) => {
      setActiveDeactiveLabError(error);
      setActiveDeactiveLabLoading(false);
      throw error;
    },
  });

  return {
    activeDeactiveLab,
    activeDeactiveLabLoading,
    activeDeactiveLabError,
    activeDeactiveLabSuccess,
  };
};

export default useActiveDeactiveLab;
