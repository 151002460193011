// useTimer.js
import { useState, useEffect } from "react";

function useTimer(initialSeconds, interval = 1000, onTimerEnd) {
  const [seconds, setSeconds] = useState(initialSeconds);
  const [isActive, setIsActive] = useState(false);

  const startTimer = () => {
    setIsActive(true);
  };

  const resetTimer = () => {
    setSeconds(initialSeconds);
    setIsActive(false);
  };

  useEffect(() => {
    let timer;
    if (isActive && seconds > 0) {
      timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, interval);
    } else if (seconds === 0) {
      setIsActive(false);
      onTimerEnd(); // Call the function when the timer ends
    }
    return () => clearInterval(timer);
  }, [isActive, seconds, interval, onTimerEnd]);

  return { seconds, isActive, startTimer, resetTimer };
}

export default useTimer;
