// slices/forgetPasswordSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Defaults to localStorage for web
import encryptionConfig from "../encryptionConfig";
const persistConfig = {
  key: "forgetPassword",
  storage,
  blacklist: [], // Add any state properties that you don't want to persist here
  ...encryptionConfig, // Integrate encryption configuration, if needed
};

export const forgetPasswordSlice = createSlice({
  name: "forgetPassword",
  initialState: {
    email: null,
    otp: null,
  },
  reducers: {
    setForgetPasswordData: (state, action) => {
      const { email } = action.payload;
      state.email = email;
    },
    setForgetPasswordDataOtp: (state, action) => {
      const { email, otp } = action.payload;
      state.email = email;
      state.otp = otp;
    },
    clearForgetPasswordData: (state) => {
      state.email = null;
    },
  },
});

const persistedForgetPasswordReducer = persistReducer(
  persistConfig,
  forgetPasswordSlice.reducer
);

export const {
  setForgetPasswordData,
  setForgetPasswordDataOtp,
  clearForgetPasswordData,
} = forgetPasswordSlice.actions;

export default persistedForgetPasswordReducer;
