import { useState } from 'react';
import { useDispatch } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from 'react-router-dom';
import { authPrefixedRoutes } from '../../../routes/all-routes/authRoutes';
import { setSignUpData } from "../../../redux/slices/signUpSlice";



const useLabSignUp = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate(); // Initialize navigate
    const [signupError, setSignupError] = useState(null);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [signupSuccessResponse, setSignupSuccessResponse] = useState(null); // Add state for success response message and data


    const { mutate: signUpLab, isLoading, isError } = useMutation({
        mutationKey: "signupLab",
        mutationFn: async (formData) => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/auth/lab/signup`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(formData),
                });

                const data = await response.json();


                if (!response.ok) {
                    // const text = await response.json();
                    // if (text?.error?.status === 401) {
                    //   navigate(authPrefixedRoutes.LOGIN);
                    // }
                    // console.log(text.error.message);

                    setSignupError(data.error.message);

                    throw new Error(data.error.message);
                    
                }

                // Set submit success flag
                setSubmitSuccess(true);

                 // Dispatch action to set sign-up data
                dispatch(setSignUpData({ email:data.data.email, panel:"lab" }));

                // Set success response data directly from the response
                setSignupSuccessResponse(data);               
                

                // Delay navigation for 1 second
                setTimeout(() => {
                 // navigate to verify otp
                navigate(authPrefixedRoutes.VERIFY_SIGNUP_OTP);
                }, 1500);

                return data;
            } catch (error) {
                // Set signup error state
                setSignupError(error);

                throw error;
            }
        },
        onError: (error) => {
            
            setSignupError(error);
            throw error;
        },
    });


    return { signUpLab, isLoading, isError, signupError, submitSuccess,signupSuccessResponse };
};

export default useLabSignUp;
