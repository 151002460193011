import AdminSideBar from "./AdminSideBar";
import AdminTopBar from "./AdminTopBar";
import "../../../../styles/innerPanel.css";

const AdminLayout = ({ children }) => {
  return (
    <div className="inner-panel d-flex vh-100 overflow-hidden  ">
      <AdminSideBar />
      <div className="position-relative d-flex flex-1 flex-column overflow-y-auto overflow-x-hidden w-100 custom-scroll">
        <AdminTopBar />
        <main>
          <div className="mx-auto pt-4 pb-4 ">{children}</div>
        </main>
      </div>
    </div>
  );
};

export default AdminLayout;
