// useGetTransactionDetailsById.js
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import authPrefixedRoutes from "../../../../routes/all-routes/authRoutes";
import { useNavigate } from "react-router-dom";

const useGetTransactionDetailsById = (transactionID) => {
  const token = useSelector((state) => state.auth.user.accessToken);
  const [transactionDetails, setTransactionDetails] = useState({});
  const [transactionDetailsError, setTransactionDetailsError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const fetchProductCategory = useQuery({
    queryKey: ["getTransactionDetailsByID"],
    queryFn: async () => {
      try {
        setIsLoading(true);
        const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/admin/transaction/${transactionID}`;
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          const text = await response.json();
          if (text?.error?.status === 401) {
            navigate(authPrefixedRoutes.LOGIN);
          }
          const text1 = await response.text();
          setTransactionDetailsError(text1);
          setIsLoading(false);
          throw new Error("Failed to fetch transaction details");
        }

        const responseData = await response.json();
        setTransactionDetails(responseData[0]); // Assuming responseData contains product

        setIsLoading(false);

        return responseData;
      } catch (error) {
        setTransactionDetailsError(error);
        setIsLoading(false);
        throw error;
      }
    },
    onError: (error) => {
      setTransactionDetailsError(error);
      setIsLoading(false);
      throw error;
    },
  });

  useEffect(() => {}, []);

  const fetchTransactionDetailWithId = (transactionID) => {
    fetchProductCategory.refetch(transactionID);
  };

  return {
    fetchTransactionDetailWithId,
    isLoading,
    transactionDetailsError,
    transactionDetails,
  };
};

export default useGetTransactionDetailsById;
