import { MessageIcon } from "../../../../utils/MyIcons";
import usePageName from "../../../../hooks/ui-hooks/usePageName";
import { Link } from "react-router-dom";
import adminPrefixedRoutes from "../../../../routes/all-routes/adminRoutes";

const AdminTopBar = () => {
  const currentPageName = usePageName("admin");

  return (
    <div className="bg-white w-100 sticky-top top-0 ">
      <div className="container admin-topbar pt-4 pb-4 d-flex justify-content-between align-items-center">
        <span className="fs-1 fw-900 text-title ">{currentPageName}</span>
        <div className="d-flex align-items-center gap-2 ">
          <Link to={adminPrefixedRoutes.MESSAGE}>
            <button className="d-flex align-items-center justify-content-center w-h-44px rounded-circle border bg-transparent">
              <MessageIcon />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default AdminTopBar;
