import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { debounce } from "lodash";
import useLabList from "./useLabList";
import useVerifyLab from "./useVerifyLab";
import useActiveDeactiveLab from "./useActiveDeactiveLab";
import { toast } from "react-toastify";

const useLabListLogic = () => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  // Function to handle page change
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [pageSize, setPageSize] = useState(10);

  const handlePageClick = ({ selected }) => {
    let page = selected + 1; // selected starts from 0
    //console.log("selected", page);
    if (page > 0) {
      setCurrentPage(page);
    }
  };

  // const pageSize = 10; // items per page

  const {
    fetchLabListWithParams,
    isLoading,
    isError,
    labList,
    setLabList,
    labListMeta,
    labListError,
  } = useLabList({
    pageNumber: currentPage,
    pageSize: pageSize,
    searchTerm: searchTerm,
    status: filterStatus,
    sortField: "createdAt",
    sortDirection: "desc",
  });

  useEffect(() => {
    // console.log("-------------------\n");
    // console.log("isLoading:", isLoading);
    // console.log("labList:", labList);
    // console.log("labListMeta:", labListMeta);
    // console.log("labListError:", labListError);
    // console.log("-------------------\n");
  }, [isLoading, labList, labListError, labListMeta]);

  useEffect(() => {
    // console.log("-------------------\n");
    // console.log("currentPage:", currentPage);
    // console.log("searchTerm:", searchTerm);
    // console.log("filterStatus:", filterStatus);
    // console.log("-------------------\n");
    fetchLabListWithParams({
      pageNumber: currentPage,
      pageSize: pageSize,
      searchTerm: searchTerm,
      status: filterStatus,
      sortField: "createdAt",
      sortDirection: "desc",
    });
  }, [currentPage, searchTerm, filterStatus]);

  const onSubmit = (data) => {
    if (isSubmitting) {
      return;
    }

    // Trigger API call with search term and filter status
    const { searchTerm, status } = data;
    setSearchTerm(searchTerm);
    setFilterStatus(status);
    setCurrentPage(1); // Reset page to 1 when searching
  };

  // Debounced form submission for input change
  const debouncedSubmit = debounce(onSubmit, 1000);

  //--------------------------------

  const { verifyLab, verifyLabLoading, verifyLabError, verifyLabSuccess } =
    useVerifyLab();

  const {
    activeDeactiveLab,
    activeDeactiveLabLoading,
    activeDeactiveLabError,
    activeDeactiveLabSuccess,
  } = useActiveDeactiveLab();

  //  to manage actions like activate deactive and verify
  const [labStates, setLabStates] = useState({});

  useEffect(() => {
    // Initialize state for each lab item
    const initialLabStates = labList.reduce((acc, lab) => {
      acc[lab._id] = {
        verifyLabLoading: false,
        verifyLabError: null,
        verifyLabSuccess: false,
        activeDeactiveLabLoading: false,
        activeDeactiveLabError: null,
        activeDeactiveLabSuccess: false,
      };
      return acc;
    }, {});
    setLabStates(initialLabStates);
  }, [labList]);

  // Function to update lab state
  const updateLabState = (labId, newState) => {
    setLabStates((prevStates) => ({
      ...prevStates,
      [labId]: { ...prevStates[labId], ...newState },
    }));
  };

  // Function to handle lab verification
  const handleVerifyLab = async (labId) => {
    try {
      // console.log("labId", labId);
      updateLabState(labId, { verifyLabLoading: true });
      verifyLab(labId);
      updateLabState(labId, {
        verifyLabLoading: false,
        verifyLabSuccess: true,
      });

      // Update the lab status in the lab list
      setLabList((prevLabList) =>
        prevLabList.map((lab) =>
          lab._id === labId
            ? {
                ...lab,
                creatorId: {
                  ...lab.creatorId,
                  isVerified: !lab.creatorId.isVerified,
                },
              }
            : lab
        )
      );

      toast.success("Lab verify status changed successfully!");
    } catch (error) {
      updateLabState(labId, {
        verifyLabLoading: false,
        verifyLabError: error.message,
      });
    }
  };

  // Function to handle lab status change
  const handleActiveDeactiveLab = async (labId) => {
    try {
      updateLabState(labId, { activeDeactiveLabLoading: true });
      activeDeactiveLab(labId);
      updateLabState(labId, {
        activeDeactiveLabLoading: false,
        activeDeactiveLabSuccess: true,
      });

      // Update the lab status in the lab list
      setLabList((prevLabList) =>
        prevLabList.map((lab) => {
          return lab._id === labId
            ? {
                ...lab,
                status: lab.status === "active" ? "inactive" : "active",
              }
            : lab;
        })
      );

      toast.success("Lab status updated successfully!");
    } catch (error) {
      updateLabState(labId, {
        activeDeactiveLabLoading: false,
        activeDeactiveLabError: error.message,
      });

      toast.error("Failed to update lab status.");
    }
  };
  // Function to handle lab status change

  return {
    register,
    pageSize,
    handleSubmit,
    onSubmit,
    isSubmitting,
    setPageSize,
    currentPage,
    setCurrentPage,
    handlePageClick,
    searchTerm,
    filterStatus,
    setFilterStatus,
    labList,
    isLoading,
    labListError,
    labListMeta,
    debouncedSubmit,
    labStates,
    handleVerifyLab,
    handleActiveDeactiveLab,
  };
};

export default useLabListLogic;
