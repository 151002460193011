import { useState } from "react";

const usePasswordToggle = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };

  const getPasswordInputType = () => {
    return passwordVisible ? "text" : "password";
  };

  const getPasswordIcon = () => {
    return passwordVisible ? "eye-open" : "eye-close";
  };

  return [getPasswordInputType, getPasswordIcon, togglePasswordVisibility];
};

export default usePasswordToggle;
