import React from "react";

const Partner = ({ data }) => {
  return (
    <div className="partner-container max-width mx-auto  px-4 d-flex justify-content-center align-items-center flex-column ">
      <div className="partner-title fw-semibold mb-4">
        {/* Trusted by greatest companies */}
        {data?.title || "Trusted by greatest companies"}
      </div>
      <div className="partner-logo-container d-flex align-items-center justify-content-around flex-wrap">
        {data?.brand_items?.map((item) => (
          <img
            key={JSON.stringify(item)}
            src={item?.image}
            alt={"Brand Image"}
            className="img-fluid mx-4 px-3 my-3"
          />
        ))}
      </div>
    </div>
  );
};

export default Partner;
